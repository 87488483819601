export const netSuiteConfiguration = {
  netsuite_custom_field_id_is_required:
    "El ID del campo personalizado de Netsuite es obligatorio",
  netsuite_data_type_is_required: "El tipo de datos de Netsuite es obligatorio",
  netsuite_tbainfo_required: "Se requiere la información de TBA",
  netsuite_custom_collection_id: "ID de colección personalizada de Netsuite",
  netsuite_custom_field_id: "ID de campo personalizado de Netsuite",
  netsuite_data_type: "Tipo de datos de Netsuite",
  netsuite_docCls_actCls_required:
    "Se requieren la clase de documento y la clase de cuenta de Netsuite",
  netsuite_integration_unsupported: `No se admite la integración con Netsuite`,
  netsuite_subsidiaryid_required: `Se requiere la identificación de la subsidiaria de Netsuite`,
  netsuite_vendorid_required: `Se requiere la identificación del proveedor de Netsuite`,
  netsuite_items_required: `Se requieren los artículos de Netsuite`,
  netsuite_itemid_or_acctid_required: `Se requiere el ID del artículo de Netsuite o el ID de la cuenta (solo debe seleccionarse uno).`,
  netsuite_item_qty_required: `Se requiere la cantidad de artículos de Netsuite`,
  netsuite_item_unitprice_required: `Se requiere el precio unitario del artículo de Netsuite`,
  netsuite_item_creation_not_supported: "Creación de artículo no compatible",
};
