import {
  Alert,
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { lighten } from "@mui/material/styles";
import { useState } from "react";
import { useNavigate } from "react-router";
import { RightArrowIcon } from "src/assets/icon/arrow/rightArrow";
import ChevronLeftIcon from "src/assets/icon/chevron/left";
import ChevronRightIcon from "src/assets/icon/chevron/right";
import InfoIcon from "src/assets/icon/info";
import Multiply from "src/assets/icon/multiply/Multiply";
import useDynamicTranslate from "src/hooks/useDynamicTranslation";
import useLocales from "src/hooks/useLocales";
import { readNotification } from "./useNotifications";

type Props = {
  notifications: TNotification[];
  displayALl?: boolean;
  setNotifications: any;
};

type TNotification = {
  id: string;
  message: string;
  notificationType: 0 | 1 | 2 | 3 | 4 | 5;
  count: number;
};

const NotificationAlertBox = ({
  notifications,
  setNotifications,
  displayALl,
}: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { translate } = useLocales();

  const handleNavigate = (notification: TNotification) => {
    readNotification(notification.id);
    if (notification.notificationType === 0) {
      navigate("/dashboard/documents");
    } else if (notification.notificationType === 1) {
      navigate("/dashboard/workflow?documents=true");
    } else if (notification.notificationType === 2) {
      notifications.splice(notifications.indexOf(notification), 1);
      setNotifications([...notifications]);
    } else if (notification.notificationType === 3) {
      navigate("/dashboard/upgrade");
    } else if (notification.notificationType === 4) {
      navigate("/dashboard/integration");
    }
  };
  if (notifications.length === 0) {
    return <></>;
  }
  return (
    <>
      <Alert
        severity="warning"
        variant="outlined"
        icon={false}
        sx={{
          display: "flex",
        }}
      >
        {!displayALl && notifications.length > 4 ? (
          <>
            <Box
              p={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Stack direction={"row"} spacing={1}>
                <InfoIcon customColor={theme.palette.warning.dark} />
                <h5
                  style={{
                    fontWeight: 800,
                  }}
                >
                  {formatCount(notifications.length)}
                </h5>
                <p
                  style={{
                    fontWeight: 800,
                    fontSize: 16,
                  }}
                >
                  {translate("Important Notifications")}
                </p>
              </Stack>
              <SquareButton
                onCLick={() => {
                  navigate("/dashboard/notifications");
                }}
              >
                {translate("See more")}
              </SquareButton>
            </Box>
          </>
        ) : (
          notifications.map((notification) => (
            <Box
              p={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              key={notification?.id}
            >
              <Stack direction={"row"} spacing={1}>
                <InfoIcon customColor={theme.palette.warning.dark} />
                <h5
                  style={{
                    fontWeight: 800,
                  }}
                >
                  {formatCount(notification.count)}
                </h5>
                <p
                  style={{
                    fontWeight: 800,
                    fontSize: 16,
                  }}
                >
                  {translate(notification.message)}
                </p>
              </Stack>
              <CircularButton
                onCLick={() => {
                  handleNavigate(notification);
                }}
              />
            </Box>
          ))
        )}
      </Alert>
    </>
  );
};

export default NotificationAlertBox;

const formatCount = (count: number) => {
  if (count > 0 && count < 10) {
    return `0${count}`;
  }
  return count;
};

export const CircularButton = ({ onCLick }) => {
  const theme = useTheme();

  return (
    <>
      <Box
        textAlign={"right"}
        sx={{
          marginLeft: "auto",
          borderRadius: "50%",
          border: "2px solid",
          position: "relative",
          padding: "2px",
          height: 26,
          width: 26,
          display: "flex",
          alignItems: "center",
          borderColor: theme.palette.warning.dark,
          bgcolor: lighten(theme.palette.warning.light, 0.6),
          cursor: "pointer",
        }}
        onClick={onCLick}
      >
        <RightArrowIcon
          height={18}
          width={18}
          customColor={theme.palette.warning.dark}
        />
      </Box>
    </>
  );
};

const SquareButton = ({ onCLick, children }) => {
  const theme = useTheme();
  return (
    <Button
      variant="outlined"
      size="small"
      sx={{
        bgcolor: lighten(theme.palette.warning.light, 0.6),
        borderColor: theme.palette.warning.dark,
        color: theme.palette.warning.dark,
      }}
      onClick={onCLick}
      endIcon={
        <RightArrowIcon
          height={18}
          width={18}
          customColor={theme.palette.warning.dark}
        />
      }
    >
      {children}
    </Button>
  );
};

export const DocumentAlertBox = ({ count, status, setStatus }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  if (status == "0") {
    return (
      <>
        <Alert
          severity={"info"}
          variant="outlined"
          icon={false}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
          }}
        >
          <Box
            p={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack direction={"row"} spacing={1}>
              <InfoIcon customColor={theme.palette.info.dark} />
              <p
                style={{
                  fontWeight: 800,
                  fontSize: 16,
                }}
              >
                {translate("Extracting Documents", {
                  count: count,
                  s: count > 1 ? "s" : "",
                })}
              </p>
            </Stack>
            <CrossRoundButton
              color={"info"}
              onCLick={() => {
                setStatus("");
              }}
            />
          </Box>
        </Alert>
      </>
    );
  } else if (status == "1") {
    return (
      <>
        <Alert
          severity={"success"}
          variant="outlined"
          icon={false}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
          }}
        >
          <Box
            p={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack direction={"row"} spacing={1}>
              <InfoIcon customColor={theme.palette.success.dark} />
              <p
                style={{
                  fontWeight: 800,
                  fontSize: 16,
                }}
              >
                {translate("The document extraction is completed")}
              </p>
            </Stack>
            <CrossRoundButton
              color={"success"}
              onCLick={() => {
                setStatus("");
              }}
            />
          </Box>
        </Alert>
      </>
    );
  }
  return <></>;
};
export const ReviewAlertBox = ({
  setAlert,
  isJsonHashValid,
  isValidHash,
  isDocumentPeriodClosed,
  documentIsDeleted,
  status,
  messages,
}) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const { dynamicTranslation } = useDynamicTranslate();

  const messageKeys = dynamicTranslation(messages, "messageKey");
  const messagesList = [
    ...(status == 4
      ? ["Some fields need to be reviewed. Show info for details."]
      : []),
    ...(isJsonHashValid ? [] : ["Document data has been updated/changed"]),
    ...(isValidHash ? [] : ["Document has been updated/changed"]),
    ...(isDocumentPeriodClosed
      ? ["Document can not be updated because the accounting period is closed."]
      : []),
    ...(documentIsDeleted ? ["Document has been deleted."] : []),
    ...(messageKeys != undefined ? messageKeys : []),
  ];

  const [showArrow, setShowArrow] = useState(false);
  const [page, setPage] = useState(0);
  const record = 3;
  const count = messagesList.length;

  return (
    <>
      {messagesList?.length > 0 && (
        <Alert
          severity={"error"}
          variant="outlined"
          icon={<InfoIcon customColor={theme.palette.error.dark} />}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
            fontSize: "11px",
            fontWeight: 500,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{}}>
              {messagesList
                ?.slice(
                  page * record,
                  page * record + record > count
                    ? count
                    : page * record + record
                )
                .map((message, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "bottom",
                      gap: 1,
                    }}
                  >
                    {translate(message)}
                  </Box>
                ))}
            </Box>
            {count > record && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "top",
                }}
              >
                <Box>
                  <Typography variant="subtitle2">
                    {page * record + 1}-
                    {page * record + record > count
                      ? count
                      : page * record + record}{" "}
                    {translate("of_")}&nbsp;
                    {count}
                  </Typography>
                </Box>
                <Box>
                  <Tooltip title={translate("previous")}>
                    <span>
                      <IconButton
                        size="small"
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            color: "black",
                            bgcolor: lighten(theme.palette.error.light, 0.6),
                          },
                          bgcolor: lighten(theme.palette.error.light, 0.6),
                          "&:disabled": {
                            bgcolor: lighten(
                              theme.palette.error.light,
                              0.6
                            ) as string,
                          },
                          mx: 1,
                        }}
                        disabled={page == 0}
                        onClick={() => {
                          if (page > 0) {
                            setPage(page - 1);
                          }
                        }}
                      >
                        <ChevronLeftIcon
                          height={16}
                          width={16}
                          color={theme.palette.error.dark}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={translate("next")}>
                    <span>
                      <IconButton
                        disabled={page + 1 >= count / record}
                        size="small"
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            color: "black",
                            bgcolor: lighten(theme.palette.error.light, 0.6),
                          },
                          "&:disabled": {
                            bgcolor: lighten(
                              theme.palette.error.light,
                              0.6
                            ) as string,
                          },
                          bgcolor: lighten(theme.palette.error.light, 0.6),
                        }}
                        onClick={() => {
                          if (page + 1 < count / record) {
                            setPage(page + 1);
                          }
                        }}
                      >
                        <ChevronRightIcon
                          height={16}
                          width={16}
                          color={theme.palette.error.dark}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            )}
          </Box>
        </Alert>
      )}
    </>
  );
};

const CrossRoundButton = ({ onCLick, color }) => {
  const theme = useTheme();

  return (
    <>
      <Box
        textAlign={"center"}
        sx={{
          borderRadius: "50%",
          position: "relative",
          padding: "2px",
          height: 26,
          width: 26,
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          bgcolor: lighten(theme.palette[color].light, 0.8),
          cursor: "pointer",
        }}
        onClick={onCLick}
      >
        <Multiply width={18} height={18} color={color} />
      </Box>
    </>
  );
};
