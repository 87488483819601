import { useRef, useState } from "react";
interface RefHandler {
  handleRotate: (v: any) => void;
}
const useReviewToolbar = ({
  MAP,
  setMAP,
  height,
  width,
  setHeight,
  setWidth,
  rotation,
  setRotation,
  transformComponentRef,
}) => {
  const [stayMultiHighlighted, setStayMultiHighlighted] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [events, setEvents] = useState<any>([]);
  const [combineAreas, setCombineAreas] = useState<any>(new Map());

  const imageRef = useRef<RefHandler>(null);

  const gatherEvents = (event) => {
    let event_ = {
      pageX: event.pageX,
      pageY: event.pageY,
      clientX: event.clientX,
      clientY: event.clientY,
    };
    events.push(event_);
    setEvents(events);
  };

  const avgEvent = () => {
    let pageX = 0;
    let pageY = 0;
    let clientX = 0;
    let clientY = 0;
    events.forEach((event) => {
      pageX += event.pageX;
      pageY += event.pageY;
      clientX += event.clientX;
      clientY += event.clientY;
    });
    if (events.length == 0) return { pageX, pageY, clientY, clientX };
    pageX = pageX / events.length;
    pageY = pageY / events.length;
    clientX = clientX / events.length;
    clientY = clientY / events.length;
    return { pageX, pageY, clientX, clientY };
  };

  const toolbarprops = {
    stayMultiHighlighted,
    editMode,
    events,
    combineAreas,
    imageRef,
  };

  const addAreaToCombine = (area: any) => {
    combineAreas[area.id] = area;
    setCombineAreas(combineAreas);
  };

  const generateLine = (angle: number, rotation: number) => {
    let bp = calculateCoordinates(Object.values(combineAreas), angle);
    let content = getContent(Object.values(combineAreas), angle + rotation);
    let line = {
      content: content,
      boundingPolygon: {
        lowerLeft: { x: bp[0], y: bp[1] },
        lowerRight: { x: bp[2], y: bp[3] },
        upperLeft: { x: bp[4], y: bp[5] },
        upperRight: { x: bp[6], y: bp[7] },
      },
    };
    return line;
  };

  // Rotate a single point
  const rotatePoint = (
    x: number,
    y: number,
    cx: number,
    cy: number,
    angle: number,
    scale: number
  ) => {
    const radians = (angle * Math.PI) / 180; // Convert angle to radians
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const dx = x * scale - cx;
    const dy = y * scale - cy;
    return [cos * dx - sin * dy + cx / scale, sin * dx + cos * dy + cy * scale];
  };

  const rotatePolyCoords = (
    coords: number[],
    cx: number,
    cy: number,
    angle: number,
    scale: number
  ) => {
    const newCoords: any = [];
    for (let i = 0; i < coords.length; i += 2) {
      const [x, y] = [coords[i], coords[i + 1]];
      const scalePoint = (
        x: number,
        y: number,
        cx: number,
        cy: number,
        scale: number
      ): [number, number, number, number] => {
        return [x * scale, y * scale, cx * scale, cy * scale];
      };
      const [newX, newY] = rotatePoint(
        ...scalePoint(x, y, cx, cy, 1),
        angle,
        scale
      );
      newCoords.push(newX, newY);
    }
    return newCoords;
  };
  const rotatePolygonMap = (
    imageHeight: number,
    imageWidth: number,
    scale: number,
    angle: number
  ) => {
    const cx = imageWidth / 2;
    const cy = imageHeight / 2;
    const newAreas = MAP.areas.map((area) => {
      if (area.shape === "poly") {
        return {
          ...area,
          coords: rotatePolyCoords(area.coords, cy, cx, angle, scale),
        };
      }
      return area;
    });

    setMAP({ ...MAP, areas: newAreas });
  };
  const setDimensions = (h: number, w: number, angle: number) => {
    setWidth(() => w);
    setHeight(() => h);
    let scale = h / width;
    let zoom = transformComponentRef?.current?.instance?.transformState?.scale;
    const content: HTMLElement | null =
      document.querySelector(".scroll-content");
    let scaleLevel = zoom;
    const scaledWidth = w * scaleLevel;
    const scaledHeight = h * scaleLevel;
    if (content) {
      content.style.transform = `scale(${scaleLevel})`;
      content.style.width = `${scaledWidth}px`;
      content.style.height = `${scaledHeight}px`;
    }
    rotatePolygonMap(h, w, scale, angle);
  };
  // Rotate the entire map
  const rotateMap = (angle: number) => {
    const newAngle = (rotation + angle) % 360;
    setRotation(newAngle);

    imageRef?.current?.handleRotate?.(setDimensions);
  };

  const resetState = () => {
    setStayMultiHighlighted(false);
    setEditMode(false);
    setEvents([]);
    setCombineAreas([]);
  };

  const editOptionsProps = {
    setStayMultiHighlighted,
    setEditMode,
    gatherEvents,
    avgEvent,
    setEvents,
    addAreaToCombine,
    setCombineAreas,
    generateLine,
    resetState,
    rotateMap,
  };

  return { toolbarprops, editOptionsProps };
};

export default useReviewToolbar;

export const getContent = (areas: any[], angle: number) => {
  let content = "";
  let lastY: number | null = null;
  const threshold = 10;

  // Convert angle to radians
  const angleInRadians = angle * (Math.PI / 180);

  // Inverse rotation matrix components
  const cosAngle = Math.cos(angleInRadians);
  const sinAngle = Math.sin(angleInRadians);

  areas?.forEach((area: any) => {
    if (area?.center && area?.title) {
      const [x, y] = area.center;

      // Rotate back the coordinates using the inverse rotation matrix
      const rotatedX = cosAngle * x + sinAngle * y;
      const rotatedY = -sinAngle * x + cosAngle * y;

      // Compare the Y-values (rotatedY is now the "upright" Y-coordinate)
      if (lastY !== null && Math.abs(rotatedY - lastY) > threshold) {
        content += "\n";
      }

      // Add the title of the area
      content += area.title + " ";
      lastY = rotatedY; // Use the rotated Y-coordinate for comparison
    }
  });

  return content.trim();
};
export const calculateCoordinates = (areas: any[], angle: number) => {
  let boundingPolygon = [0, 0, 0, 0, 0, 0, 0, 0];
  const radian = (angle * Math.PI) / 180; // Convert angle to radians

  // Function to rotate a point (x, y) by the given angle
  const rotatePoint = (x: number, y: number, angleInRadian: number) => {
    const xNew = x * Math.cos(angleInRadian) - y * Math.sin(angleInRadian);
    const yNew = x * Math.sin(angleInRadian) + y * Math.cos(angleInRadian);
    return { x: xNew, y: yNew };
  };

  // Step 1: Transform all points to zero rotation
  const transformedAreas = areas.map((area: any) => {
    const bp = area.boundingPolygon;

    return {
      boundingPolygon: {
        lowerLeft: rotatePoint(bp?.lowerLeft?.x, bp?.lowerLeft?.y, -radian),
        lowerRight: rotatePoint(bp?.lowerRight?.x, bp?.lowerRight?.y, -radian),
        upperLeft: rotatePoint(bp?.upperLeft?.x, bp?.upperLeft?.y, -radian),
        upperRight: rotatePoint(bp?.upperRight?.x, bp?.upperRight?.y, -radian),
      },
    };
  });

  // Step 2: Use the original logic on the transformed coordinates
  transformedAreas.map((area: any) => {
    let bp = area.boundingPolygon;

    if (bp?.lowerLeft?.x > boundingPolygon[0] || boundingPolygon[0] == 0) {
      boundingPolygon[0] = bp?.lowerLeft?.x;
    }
    if (bp?.lowerLeft?.y > boundingPolygon[1] || boundingPolygon[1] == 0) {
      boundingPolygon[1] = bp?.lowerLeft?.y;
    }
    if (bp?.lowerRight?.x < boundingPolygon[2] || boundingPolygon[2] == 0) {
      boundingPolygon[2] = bp?.lowerRight?.x;
    }
    if (bp?.lowerRight?.y > boundingPolygon[3] || boundingPolygon[3] == 0) {
      boundingPolygon[3] = bp?.lowerRight?.y;
    }
    if (bp?.upperLeft?.x < boundingPolygon[4] || boundingPolygon[4] == 0) {
      boundingPolygon[4] = bp?.upperLeft?.x;
    }
    if (bp?.upperLeft?.y < boundingPolygon[5] || boundingPolygon[5] == 0) {
      boundingPolygon[5] = bp?.upperLeft?.y;
    }
    if (bp?.upperRight?.x > boundingPolygon[6] || boundingPolygon[6] == 0) {
      boundingPolygon[6] = bp?.upperRight?.x;
    }
    if (bp?.upperRight?.y < boundingPolygon[7] || boundingPolygon[7] == 0) {
      boundingPolygon[7] = bp?.upperRight?.y;
    }
  });

  // Step 3: Rotate the final bounding polygon back to the original angle
  const finalBoundingPolygon = [
    rotatePoint(boundingPolygon[0], boundingPolygon[1], radian),
    rotatePoint(boundingPolygon[2], boundingPolygon[3], radian),
    rotatePoint(boundingPolygon[4], boundingPolygon[5], radian),
    rotatePoint(boundingPolygon[6], boundingPolygon[7], radian),
  ];

  // Flatten the rotated points back into the boundingPolygon format
  return finalBoundingPolygon.flatMap(({ x, y }) => [x, y]);
};
