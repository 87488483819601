import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  SelectChangeEvent,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
} from "@mui/material";
import { getDate, getMonth, getYear } from "date-fns";
import { useSnackbar } from "notistack";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import Active from "src/assets/icon/active/Active";
import CrossIcon from "src/assets/icon/cross";
import { DownloadIcon } from "src/assets/icon/download";
import FileIcon from "src/assets/icon/file";
import services from "src/common/fileManagerService";
import { GetAsync, PatchAsync } from "src/common/httpRequests";
import Page from "src/components/Page";
import MyTablePagination from "src/components/pagination/TablePagination";
import {
  TableMoreMenu,
  TableNoData,
  TableSelectedActions,
  TableSkeleton,
} from "src/components/table";
import { API_GATEWAY } from "src/constants/settings";
import useInterval from "src/hooks/useInterval";
import useLocales from "src/hooks/useLocales";
import useSettings from "src/hooks/useSettings";
import useTable from "src/hooks/useTable";
import DiscrepancyDrawer from "src/pages/Documents/Finance/DiscrepancyDrawer";
import useDiscrepancyDrawer from "src/pages/Documents/Finance/DiscrepancyDrawer/useDiscrepancyDrawer";
import { applicationTypeMap } from "src/pages/Documents/Finance/table/table";
import { WorkflowApprovalDocuments } from "../type";
import SearchFilters from "./Filters";
import TableHeadCustom from "./tableHeadeCustom";
import FinanceTableRow from "./TableRow";
import useApprovalFilter from "./useApprovalFilter";
import WorkflowMultiApproveRejectDialog from "./WorkflowMultiApproveRejectDialog";

export interface ApprovalDocuments {
  workflowId: string;
  setDocument: Dispatch<SetStateAction<any>>;
}

export default function InvoiceList({
  workflowId,
  setDocument,
}: ApprovalDocuments) {
  const date = new Date();
  const SKELETON: string[] = ["", "", "", "", "", "", ""];

  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const { translate } = useLocales();

  const TABLE_HEAD = [
    {
      id: "invoiceNumber",
      label: translate(`Document Name`),
      align: "left",
      icon: (
        <FileIcon
          sx={{
            mr: "7px",
          }}
        />
      ),
    },
    {
      id: "workflowName",
      label: translate(`Workflow Name`),
      align: "left",
    },
    {
      id: "date",
      label: translate(`Processed on`),
      align: "left",
    },
    {
      id: "status",
      label: translate(`Status in workflow`),
    },
    {
      id: "review",
      label: translate(`Action`),
      align: "left",
    },
  ];

  const {
    order,
    orderBy,
    selected,
    onSelectRow,
    setSelected,
    onSelectAllRows,
  } = useTable({
    defaultOrderBy: "createDate",
    defaultCurrentPage: 1,
    defaultRowsPerPage: 25,
  });
  const { filterState, setFilterState } = useApprovalFilter({
    record: 10,
    page: 1,
  });
  const [tabs, setTabs] = useState<string[]>(["all"]);
  const [tableData, setTableData] = useState<WorkflowApprovalDocuments[]>([]);
  const [loaderSkeleton, setLoaderSkeleton] = useState(true);
  const [noOfItems, setNoOfItems] = useState(0);
  const [firstRender, setFirstRender] = useState(true);
  const [selectAnchorEl, setSelectAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [alertConfig, setAlertConfig] = useState({
    alert: "",
    id: "",
    workflowId: "",
    documentId: "",
  });
  const [dialogLoading, setDialogLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isShowMultiApproveRejectDialog, setIsShowMultiApproveRejectDialog] =
    useState(false);
  const [documentAction, setDocumentAction] = useState("");
  const [isLoadingMultiDocuments, setIsLoadingMultiDocuments] = useState(false);

  const toggleMultiApproveRejectDialog = () => {
    setIsShowMultiApproveRejectDialog(!isShowMultiApproveRejectDialog);
  };

  const {
    handleDrawerOpen: handleDrawerOpenDiscrepancy,
    ...restDiscrepancyDrawerProps
  } = useDiscrepancyDrawer();

  const fetchData = useCallback(async () => {
    setLoaderSkeleton(true);
    let params = getParams(filterState);
    await GetAsync(
      `${API_GATEWAY}/api/workflowservice/workflowapprovals/documents`,
      true,
      undefined,
      {
        params: params,
      }
    )
      .then((res: any) => {
        setTableData(res?.data?.userWorkflowApprovalDocuments);
        setNoOfItems(res?.data?.filteredRecordsCount);
        setLoaderSkeleton(false);
        if (tabs.length === 1) {
          setTabs([...tabs, ...res?.data?.documentClasses]);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoaderSkeleton(false);
      });
  }, [tabs]);

  const getParams = (values) => {
    let params = {
      documentName: values?.documentName || undefined,
      documentStatus: values?.documentStatus || undefined,
      documentClass:
        values.documentClass === "all"
          ? undefined
          : values.documentClass || undefined,
      fromDate: values?.fromDate || undefined,
      toDate: values?.toDate || undefined,
      workflowId: workflowId || undefined,
      page: values?.page || undefined,
      record: values?.record || undefined,
      status: values?.approvalStatus || undefined,
    };
    return params;
  };

  const renderDocument = (values) => {
    let params = getParams(values);
    return GetAsync(
      `${API_GATEWAY}/api/workflowservice/workflowapprovals/documents`,
      true,
      undefined,
      {
        params: params,
      }
    ).then((res: any) => {
      setTableData(res?.data?.userWorkflowApprovalDocuments ?? []);
      setNoOfItems(res?.data?.filteredRecordsCount ?? 0);
    });
  };

  useInterval(
    () => {
      if (filterState.page > 0) {
        renderDocument(filterState);
      }
    },
    10000,
    [filterState]
  );

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setSelectAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSelectAnchorEl(null);
  };

  const handleClickOpen = (
    alert: string,
    id: string,
    workflowId: string,
    documentId: string
  ) => {
    setAlertConfig({
      alert: alert,
      id: id,
      workflowId: workflowId,
      documentId: documentId,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setAlertConfig({ alert: "", id: "", workflowId: "", documentId: "" });
    setOpen(false);
  };

  const handleApprove = () => {
    setDialogLoading(true);
    if (alertConfig.alert === "approve") {
      PatchAsync(
        `${API_GATEWAY}/api/workflowservice/workflowapprovals/approved`,
        {
          id: alertConfig.id,
          workflowId: alertConfig.workflowId,
          documentId: alertConfig.documentId,
        }
      )
        .then((res: any) => {
          if (res?.status >= 200 && res?.status <= 299) {
            enqueueSnackbar(translate(`successfully_approved`), {
              variant: "success",
            });
            renderDocument(filterState).finally(() => {});
          } else {
            enqueueSnackbar(`${res?.data?.Detail}`, { variant: "error" });
          }
        })
        .catch((err: any) => {
          enqueueSnackbar(`${err?.data?.Detail}`, { variant: "error" });
          console.log("Error-->", err);
        })
        .finally(() => {
          setAlertConfig({ alert: "", id: "", workflowId: "", documentId: "" });
          setOpen(false);
          setDialogLoading(false);
        });
    } else if (alertConfig.alert === "reject") {
      PatchAsync(
        `${API_GATEWAY}/api/workflowservice/workflowapprovals/rejected`,
        {
          id: alertConfig.id,
        }
      )
        .then((res: any) => {
          if (res?.status >= 200 && res?.status <= 299) {
            enqueueSnackbar(translate(`successfully_rejected`), {
              variant: "success",
            });
            renderDocument(filterState).finally(() => {});
          } else {
            enqueueSnackbar(`${res?.data?.Detail}`, { variant: "error" });
          }
        })
        .catch((err: any) => {
          enqueueSnackbar(`${err?.data?.Detail}`, { variant: "error" });
          console.log("Error-->", err);
        })
        .finally(() => {
          setAlertConfig({ alert: "", id: "", workflowId: "", documentId: "" });
          setOpen(false);
          setDialogLoading(false);
        });
    }
  };

  const optionsStatus = [
    { enum: "", value: `${translate(`finance_screen.all`)}` },
    { enum: "1", value: `${translate(`status.processed`)}` },
    { enum: "4", value: `${translate(`status.need_review`)}` },
    { enum: "5", value: `${translate(`status.reviewed`)}` },
    { enum: "3", value: `${translate(`status.error`)}` },
    { enum: "6", value: `${translate(`status.duplicate`)}` },
    { enum: "2", value: `${translate(`status.failed`)}` },
  ];

  const optionsApprovalStatus = [
    { enum: "", value: `${translate(`finance_screen.all`)}` },
    { enum: "99999", value: `${translate(`approval not required`)}` },
    { enum: "0", value: `${translate(`Approval required`)}` },
    { enum: "1", value: `${translate(`approved`)}` },
    { enum: "2", value: `${translate(`rejected`)}` },
  ];

  const handleExportCSVs = async () => {
    const res: any = await services.ExportCsv(
      selected,
      2,
      `csvFiles_${getDate(date)}_${getMonth(date)}_${getYear(date)}.zip`
    );
    try {
      if (res && res.status === 200) {
        const blob = new Blob([await res.blob()], {
          type: res.headers?.get("Content-Type") ?? "application/csv",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        let extension =
          applicationTypeMap[res.headers.get("Content-Type")] ?? "csv";
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `${extension ?? "csv"} Export_${getDate(date)}_${getMonth(
            date
          )}_${getYear(date)}.${extension ?? "csv"}`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        enqueueSnackbar(res.data.Detail, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(translate(`something_went_wrong`), { variant: "error" });
    }
  };

  const handleApproveWorkflows = async () => {
    setIsLoadingMultiDocuments(true);
    const approvalDocuments = tableData
      .filter((workflow) => selected.includes(workflow.documentId))
      .map((item) => ({
        id: item.id,
        documentId: item.documentId,
        workflowId: item.workflowId,
      }));
    try {
      const responses = await Promise.all(
        approvalDocuments.map(async (doc) => {
          try {
            const res = await PatchAsync(
              `${API_GATEWAY}/api/workflowservice/workflowapprovals/approved`,
              doc
            );
            if (res?.status >= 200 && res?.status <= 299) {
              enqueueSnackbar(translate(`successfully_approved`), {
                variant: "success",
              });
            } else {
              enqueueSnackbar(`${res?.data?.Detail}`, { variant: "error" });
            }
            return res;
          } catch (err) {
            enqueueSnackbar(`${err?.data?.Detail}`, { variant: "error" });
            throw err;
          }
        })
      );
      await renderDocument(filterState);
    } catch (error) {
      console.error("Error during approval workflows:", error);
    } finally {
      setIsLoadingMultiDocuments(false);
      toggleMultiApproveRejectDialog();
      setSelected([]);
    }
  };

  const handleRejectWorkflows = async () => {
    setIsLoadingMultiDocuments(true);
    const rejectedDocuments = tableData
      .filter((workflow) => selected.includes(workflow.documentId))
      .map((item) => ({
        id: item.id,
      }));

    try {
      const responses = await Promise.all(
        rejectedDocuments.map(async (doc) => {
          try {
            const res = await PatchAsync(
              `${API_GATEWAY}/api/workflowservice/workflowapprovals/rejected`,
              doc
            );
            if (res?.status >= 200 && res?.status <= 299) {
              enqueueSnackbar(translate(`successfully_rejected`), {
                variant: "success",
              });
            } else {
              enqueueSnackbar(`${res?.data?.Detail}`, { variant: "error" });
            }
            return res;
          } catch (err) {
            enqueueSnackbar(`${err?.data?.Detail}`, { variant: "error" });
            throw err;
          }
        })
      );
      await renderDocument(filterState);
    } catch (error) {
      console.error("Error during approval workflows:", error);
    } finally {
      setIsLoadingMultiDocuments(false);
      toggleMultiApproveRejectDialog();
      setSelected([]);
    }
  };

  const handleSelectAllRows = (checked: boolean) => {
    const workflowsPendingApproval = tableData.map((row) => row.documentId);

    setSelected(
      (prevSelectedRows) =>
        checked
          ? Array.from(
              new Set([...prevSelectedRows, ...workflowsPendingApproval])
            ) // Add if checked
          : prevSelectedRows.filter((row) =>
              workflowsPendingApproval.includes(row)
            ) // Remove if unchecked
    );
  };

  const handleMultiApproveRejectAction = () => {
    if (documentAction === "approve") {
      return handleApproveWorkflows;
    } else {
      return handleRejectWorkflows;
    }
  };

  const checkSelectedWorkflowApprovalRequired = () => {
    const selectedWorkflows = tableData
      .filter((item) => selected.includes(item.documentId))
      .map((workflow) => workflow);
    return selectedWorkflows.some((item) => item.status !== 0);
  };

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      fetchData();
    } else {
      setLoaderSkeleton(true);
      renderDocument(filterState).finally(() => {
        setLoaderSkeleton(false);
      });
    }
  }, [filterState]);

  return (
    <Page title="Processed Documents">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <div>
          <DiscrepancyDrawer {...restDiscrepancyDrawerProps} />

          <WorkflowMultiApproveRejectDialog
            isShowModal={isShowMultiApproveRejectDialog}
            showModalMethod={toggleMultiApproveRejectDialog}
            handleMultiApproveRejectAction={handleMultiApproveRejectAction!}
            documentAction={documentAction}
            isSubmitting={isLoadingMultiDocuments}
            docCount={selected.length}
          />

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {alertConfig.alert === "approve"
                ? translate("approval_screen.alertApproveTitle")
                : translate("approval_screen.alertRejectTitle")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {alertConfig.alert === "approve"
                  ? translate("approval_screen.alertApproveMessage")
                  : translate("approval_screen.alertRejectMessage")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="outlined"
                size="small"
                disabled={dialogLoading}
              >
                {translate("approval_screen.no")}
              </Button>
              <LoadingButton
                onClick={handleApprove}
                autoFocus
                loading={dialogLoading}
                variant="contained"
                size="small"
              >
                {translate("approval_screen.yes")}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </div>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filterState?.documentClass || "all"}
          onChange={(e, value) => {
            setFilterState({ ...filterState, documentClass: value, page: 1 });
          }}
        >
          {tabs.map((tab) => (
            <Tab disableRipple key={tab} value={tab} label={translate(tab)} />
          ))}
        </Tabs>
        <SearchFilters
          {...{
            loader: loaderSkeleton,
            filterState,
            setFilterState,
            optionsStatus,
            optionsApprovalStatus,
          }}
        />
        {console.log({ selected })}
        <TableContainer sx={{ minWidth: 800, position: "relative" }}>
          {selected.length > 0 && (
            <TableSelectedActions
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row: WorkflowApprovalDocuments) => {
                    console.log(row);
                    return row?.documentId;
                  })
                )
              }
              actions={
                <Box
                  sx={{
                    px: 3,
                    cursor: "pointer",
                  }}
                >
                  <TableMoreMenu
                    open={selectAnchorEl}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                      <>
                        {/* <MenuItem
                          onClick={() => {
                            handleDownloadInvoices("invoiceFiles_");
                            handleCloseMenu();
                          }}
                        >
                          <DownloadIcon />

                          {translate("Export original(s)")}
                        </MenuItem> */}
                        <MenuItem
                          onClick={() => {
                            if (checkSelectedWorkflowApprovalRequired()) {
                              enqueueSnackbar(
                                translate(
                                  "selected_documents_already_approved_or_rejected"
                                ),
                                { variant: "error" }
                              );
                            } else {
                              setDocumentAction("approve");
                              toggleMultiApproveRejectDialog();
                            }
                            handleCloseMenu();
                          }}
                        >
                          <Active width="17" height="17" />
                          {translate("Approve")}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            if (checkSelectedWorkflowApprovalRequired()) {
                              enqueueSnackbar(
                                translate(
                                  "selected_documents_already_approved_or_rejected"
                                ),
                                { variant: "error" }
                              );
                            } else {
                              setDocumentAction("reject");
                              toggleMultiApproveRejectDialog();
                            }
                            handleCloseMenu();
                          }}
                        >
                          <CrossIcon width="17" height="17" />
                          {translate("Reject")}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleExportCSVs();
                            handleCloseMenu();
                          }}
                        >
                          {" "}
                          <DownloadIcon
                            height={20}
                            width={20}
                            sx={{
                              height: "auto",
                              width: "auto",
                            }}
                          />
                          {translate("Export csv(s)")}
                        </MenuItem>
                        {/* <MenuItem
                          onClick={() => {
                            handleDownloadJSON();
                            handleCloseMenu();
                          }}
                        >
                          <JsonIcon
                            disableDimension
                            customColor={theme.palette.action.active}
                          />
                          {translate(
                            `Export JSON ${selected?.length > 0 ? "(s)" : ""}`
                          )}
                        </MenuItem> */}
                        {/* <MenuItem
                          onClick={() => {
                            onExportDocument();
                            handleCloseMenu();
                          }}
                        >
                          <DownloadIcon />
                          {translate(`download_via_template`)}
                        </MenuItem> */}
                      </>
                    }
                    icon={
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00977 2H8.01977M2 2H2.01M14.0195 2H14.0295"
                          stroke="#657482"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                  />

                  {/* <Tooltip title={translate("delete")} placement="top">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setIsDeleteDocumentModalVisible(true);
                      }}
                    >
                      <DeleteIcon
                        height={20}
                        width={20}
                        customColor={theme.palette.action.active}
                      />
                    </IconButton>
                  </Tooltip> */}
                </Box>
              }
            />
          )}
          <Table size={"small"}>
            <TableHeadCustom
              order={order}
              loader={loaderSkeleton}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
              numSelected={selected?.length}
              onSelectAllRows={handleSelectAllRows}
            />
            <TableBody>
              {loaderSkeleton
                ? SKELETON.map((_, index) => <TableSkeleton key={index} />)
                : tableData?.map((row: WorkflowApprovalDocuments) => (
                    <FinanceTableRow
                      key={row?.id}
                      row={row}
                      loader={loaderSkeleton}
                      handleClickOpen={handleClickOpen}
                      setDocument={setDocument}
                      selected={selected.includes(row?.documentId)}
                      onSelectRow={() => onSelectRow(row?.documentId)}
                      onViewDiscrepancyDrawer={(row, rowId) =>
                        handleDrawerOpenDiscrepancy(row, rowId)
                      }
                    />
                  ))}
              <TableNoData
                isNotFound={tableData?.length === 0 && !loaderSkeleton}
              />
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ position: "relative" }}>
          <MyTablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            count={noOfItems}
            rowsPerPage={filterState?.record || 10}
            page={filterState?.page - 1 || 0}
            onPageChange={(event, page) => {
              setFilterState({ ...filterState, page: page + 1 });
            }}
            onRowsPerPageChange={(event: SelectChangeEvent<any>) => {
              setFilterState({
                ...filterState,
                page: 1,
                record: parseInt(event.target.value, 10),
              });
            }}
          />
        </Box>
      </Container>
    </Page>
  );
}
