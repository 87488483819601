const EmptyDocumentsIllustration = () => (
  <>
    <svg
      width="73"
      height="70"
      viewBox="0 0 73 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.5"
        y="27.5"
        width="70"
        height="42"
        fill="white"
        stroke="#05315E"
        strokeDasharray="2 2"
      />
      <path
        d="M16.5586 19.5976C16.5586 15.7317 19.6926 12.5977 23.5586 12.5977H40.9738C42.7962 12.5977 44.5467 13.3084 45.8534 14.5787L52.5981 21.1361C53.9538 22.4541 54.7186 24.2644 54.7186 26.1551V50.6477C54.7186 54.5136 51.5846 57.6477 47.7186 57.6477H23.5586C19.6926 57.6477 16.5586 54.5137 16.5586 50.6477V19.5976Z"
        fill="#F4F6F8"
        stroke="#05315E"
      />
      <g filter="url(#filter0_d_5039_140124)">
        <path
          d="M46.4785 25.1977H54.7185C54.7185 23.5747 54.0859 22.0157 52.9551 20.8516L46.65 14.3611C45.5549 13.2338 44.0502 12.5977 42.4785 12.5977V21.1977C42.4785 23.4068 44.2694 25.1977 46.4785 25.1977Z"
          fill="white"
        />
        <path
          d="M42.9785 13.1212C44.231 13.2395 45.4072 13.7993 46.2914 14.7095L52.5965 21.2C53.5199 22.1506 54.0821 23.3875 54.1967 24.6977H46.4785C44.5455 24.6977 42.9785 23.1307 42.9785 21.1977V13.1212Z"
          stroke="#05315E"
        />
      </g>
      <path
        d="M24.4785 35.3469C24.4785 34.767 24.9486 34.2969 25.5285 34.2969H36.6885C37.1372 34.2969 37.5363 34.5819 37.6818 35.0063V35.0063C37.9154 35.6879 37.409 36.3969 36.6885 36.3969H25.5285C24.9486 36.3969 24.4785 35.9268 24.4785 35.3469V35.3469Z"
        stroke="#05315E"
        strokeLinejoin="round"
      />
      <path
        d="M24.4785 40.9484C24.4785 40.3685 24.9486 39.8984 25.5285 39.8984H45.7485C46.3284 39.8984 46.7985 40.3685 46.7985 40.9484V40.9484C46.7985 41.5283 46.3284 41.9984 45.7485 41.9984H25.5285C24.9486 41.9984 24.4785 41.5283 24.4785 40.9484V40.9484Z"
        stroke="#05315E"
        strokeLinejoin="round"
      />
      <ellipse
        cx="51.36"
        cy="54.1"
        rx="9.36"
        ry="9.1"
        fill="#F4F6F8"
        stroke="#05315E"
      />
      <path
        d="M50.2812 49.58C50.2812 48.9835 50.7648 48.5 51.3613 48.5V48.5C51.9577 48.5 52.4413 48.9835 52.4413 49.58V53.72C52.4413 54.3165 51.9577 54.8 51.3613 54.8V54.8C50.7648 54.8 50.2812 54.3165 50.2812 53.72V49.58Z"
        stroke="#05315E"
        strokeLinejoin="round"
      />
      <path
        d="M50.2812 58.6492C50.2812 59.2291 50.7514 59.6992 51.3312 59.6992H51.3913C51.9711 59.6992 52.4413 59.2291 52.4413 58.6492V58.6492C52.4413 58.0693 51.9711 57.5992 51.3913 57.5992H51.3312C50.7514 57.5992 50.2812 58.0693 50.2812 58.6492V58.6492Z"
        stroke="#05315E"
        strokeLinejoin="round"
      />
      <mask
        id="path-10-outside-1_5039_140124"
        maskUnits="userSpaceOnUse"
        x="21"
        y="18"
        width="16"
        height="11"
        fill="black"
      >
        <rect fill="white" x="21" y="18" width="16" height="11" />
        <path d="M22 19H36V28H22V19Z" />
      </mask>
      <path d="M22 19H36V28H22V19Z" fill="white" />
      <path
        d="M22 19V18C21.4477 18 21 18.4477 21 19H22ZM36 19H37C37 18.4477 36.5523 18 36 18V19ZM36 28V29C36.5523 29 37 28.5523 37 28H36ZM22 28H21C21 28.5523 21.4477 29 22 29V28ZM22 20H36V18H22V20ZM35 19V28H37V19H35ZM36 27H22V29H36V27ZM23 28V19H21V28H23Z"
        fill="#05315E"
        mask="url(#path-10-outside-1_5039_140124)"
      />
      <defs>
        <filter
          id="filter0_d_5039_140124"
          x="38.4785"
          y="12.5977"
          width="20.2402"
          height="20.6016"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5039_140124"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5039_140124"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </>
);

export default EmptyDocumentsIllustration;
