import {
  createLabelsBoundingBoxes,
  createOcrBoundingBoxes,
} from "../Component/dataView/BoundingBox";

export const generateAreaMap = (
  values,
  currentPage,
  data,
  height,
  width,
  rotation
) => {
  const MAP = { name: "my-map", areas: [] as any[] };
  let areas: any[] = [];
  values.fields.forEach((l: any, index: number) => {
    if (
      l.pageNumber === currentPage &&
      l.itemsFields == null &&
      l.boundingPolygon != null
    ) {
      let box = createLabelsBoundingBoxes(
        l,
        index,
        height,
        width,
        data?.["height"],
        data?.["width"],
        rotation
      );
      box.key = `fields.${index}`;
      box.id = `fields.${index}`;
      l.key = `fields.${index}`;
      l.category = "general";
      box.category = "general";
      areas.push(box);
    }
    if (l.itemsFields?.length > 0) {
      l.itemsFields?.forEach((item, idx) => {
        item?.item?.forEach((i: any, ind: number) => {
          if (i.pageNumber == currentPage && i.boundingPolygon != null) {
            let box = createLabelsBoundingBoxes(
              i,
              index,
              height,
              width,
              data?.["height"],
              data?.["width"],
              rotation
            );
            box.key = `fields.${index}.itemsFields.${idx}.item.${ind}`;
            i.key = `fields.${index}.itemsFields.${idx}.item.${ind}`;
            box.id = `fields.${index}.itemsFields.${idx}.item.${ind}`;
            i.category = l.name;
            box.category = l.name;
            l.category = l.name;
            areas.push(box);
          }
        });
        l.key = `fields.${index}`;
      });
    }
  });
  data?.lines?.forEach((l: any, index: number) => {
    let box = createOcrBoundingBoxes(
      l,
      index,
      height,
      width,
      data["height"],
      data["width"],
      rotation
    );
    box.pageNumber = currentPage;
    areas.push(box);
  });
  MAP.areas = areas;
  return {
    MAP: MAP,
  };
};
