export const holdedConfiguration = {
  holded_configurations: "Configuraciones de Holded",
  "Last sync date": "Última fecha de sincronización",
  "Sync Now": "Sincronizar ahora",
  "Entities being synced": "Entidades en proceso de sincronización",
  "Synced successfully": "Sincronizado con éxito",
  "Updated successfully": "Actualizado con éxito",
  "Failed to update": "No se pudo actualizar",
  "Sync request sent": "Solicitud de sincronización enviada",
  "There were some issues encountered during the synchronization process":
    "Se encontraron algunos problemas durante el proceso de sincronización.",
  "Add single / multiple API keys by pressing 'Enter' after each one.":
    "Agregue una o varias claves API presionando 'Enter' después de cada una.",
  "Sync start date": "Fecha de inicio de sincronización",
  "API keys": "Claves API",
  connector_not_connected: "Conector no conectado",
  connector_being_synced: "Conector en proceso de sincronización",
  connector_being_sycned: "Conector en proceso de sincronización",
  holdedV2_not_enabled: "No estás conectado con Holded v2",
  authentication_failed: "Tus claves API de Holded no pudieron autenticarse",
  connector_not_defined: "La clave ya existe",
  something_went_wrong: "Algo salió mal",
  holded_companykey_required: `La clave de la empresa de Holded es obligatoria`,
  holded_vendorname_required: `El nombre del proveedor de Holded es obligatorio`,
  holded_invoicedate_required: `La fecha de la factura de Holded es obligatoria`,
  holded_invoiceid_required: `El ID de la factura de Holded es obligatorio`,
  holded_items_required: `Los artículos de Holded son obligatorios`,
  holded_integration_unsupported: `La integración de Holded no es compatible`,
  holded_postprocessed_json_required: `El JSON postprocesado de Holded es obligatorio`,
  holded_docCls_actCls_required: `La clase de documento y la clase de cuenta de Holded son obligatorias`,
  holded_item_qty_required: `La cantidad de artículos de Holded es obligatoria`,
  holded_item_unitprice_required: `El precio unitario de los artículos de Holded es obligatorio`,
};
