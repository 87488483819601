import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
import { OpenIcon } from "src/assets/icon/Open";
import Label from "src/components/Label";
import useLocales from "src/hooks/useLocales";

export const MessageTooltip = ({
  children,
  messageKeys,
  onViewMessage,
  row,
}) => {
  const { translate } = useLocales();
  const theme = useTheme();

  const [messageTooltip, setMessageTooltip] = useState(false);

  const handleOpenMessageTooltip = () => {
    setMessageTooltip(true);
    checkOverflow();
  };

  const handleCloseMessageTooltip = () => {
    setMessageTooltip(false);
  };

  const contentRef = useRef<HTMLDivElement | HTMLUListElement | null>(null);
  const ulContentRef = useRef<HTMLUListElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = () => {
    const element1 = contentRef.current;
    const element2 = ulContentRef.current;
    if (element1) {
      // Check if content exceeds the height of the container
      setIsOverflowing(element1?.scrollHeight > element1.clientHeight);
    } else if (element2) {
      // Check if content exceeds the height of the container
      setIsOverflowing(element2?.scrollHeight > element2.clientHeight);
    }
    console.log("isO", isOverflowing);
  };

  return (
    <Tooltip
      title={
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                }}
                variant="body2"
              >
                {translate("Messages")}
              </Typography>
            </Box>
            <Box>
              <Label
                color={"info"}
                sx={{
                  height: "0.9rem",
                  fontWeight: 500,
                  fontSize: theme.spacing(1.2),
                }}
              >
                {messageKeys?.length}
              </Label>
              <span>
                <IconButton
                  size="small"
                  onClick={() => {
                    onViewMessage(row);
                    handleCloseMessageTooltip();
                  }}
                >
                  <OpenIcon height={18} width={18} />
                </IconButton>
              </span>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              py: 1,
            }}
          >
            {messageKeys?.length === 1 ? (
              <Typography
                ref={contentRef}
                variant="body2"
                sx={{
                  fontWeight: 400,
                  whiteSpace: "wrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  wordBreak: "break-word",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                }}
              >
                {messageKeys[0]}
              </Typography>
            ) : (
              <ul
                ref={ulContentRef}
                style={{
                  marginLeft: "-17px",
                  marginBottom: "-0.2px",
                  fontWeight: 400,
                  whiteSpace: "wrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  wordBreak: "break-word",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                }}
              >
                {messageKeys?.map((item, index) => {
                  return <li key={`tooltip-${index}`}>{item}</li>;
                })}
              </ul>
            )}
          </Box>
          <Box>
            {messageKeys && isOverflowing && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  sx={{
                    fontSize: "10px",
                    color: "primary.main",
                    p: 0,
                    //style it like link
                    "&:hover": {
                      textDecoration: "underline",
                      color: theme.palette.text.secondary,
                    },
                  }}
                  onClick={() => {
                    onViewMessage(row);
                    handleCloseMessageTooltip();
                  }}
                >
                  {translate("See more")}
                </Button>
              </Box>
            )}
          </Box>
        </>
      }
      arrow
      placement="top"
      open={messageTooltip}
      onOpen={handleOpenMessageTooltip}
      onClose={handleCloseMessageTooltip}
    >
      {children}
    </Tooltip>
  );
};
