const transactions = {
  transactions: `Transacciones`,
  transaction: `Transacción`,
  no_transactions_found: `No se encontraron transacciones`,
  sent_money_to: `Envió dinero a`,
  received_money_from: `Recibió dinero de`,
  transactedAt: `Transaccionado en`,
  not_available_testing_mode: "No disponible en modo de prueba",
  gateway_not_connected: "Pasarela de pago no conectada",
  transacted_by: `Realizado por`,
  transacted_on: `Realizado el`,
  status: `Estado`,
  received: `Recibido`,
  sent: `Enviado`,
};
export default transactions;
