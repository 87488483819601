import { Chip, Divider, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface DeletedIndicatorProps {
  isDeleted: boolean;
}

const DeletedIndicator: React.FC<DeletedIndicatorProps> = ({ isDeleted }) => {
  const theme = useTheme();
  const { t: translate } = useTranslation();

  if (!isDeleted) {
    return null;
  }

  return (
    <>
      <Divider
        orientation="vertical"
        sx={{
          height: "20px",
          width: "20px",
          color: "action.active",
          transform: "rotate(270deg)",
        }}
      />
      <Chip
        variant="outlined"
        size="small"
        label={translate("Deleted")}
        sx={{
          borderRadius: "4px",
          height: "20px",
          fontSize: "10px",
          bgcolor: "error.light",
          border: `1px solid ${theme?.palette?.error?.dark}`,
          pointerEvents: "auto",
          color: theme?.palette?.error?.dark,
        }}
      />
    </>
  );
};

export default DeletedIndicator;
