import { fourRetailConfiguration } from "./configuration/4Retail";
import { businessCentralConfiguration } from "./configuration/businessCentral";
import { holdedConfiguration } from "./configuration/holded";
import { netSuiteConfiguration } from "./configuration/netsuite";
import { sage } from "./configuration/sage";

const integration = {
  integrations_connected: `Integrations connected`,
  integration_not_connected: `You don’t have any integration connected yet`,
  erp_connected: `ERP connected`,
  new_integration_button_text: `New Integration`,
  add_integration: `Add integration`,
  data_source_connected: `Data source connected`,
  available_integrations: `Integrations available to connect`,
  ERP: `ERP`,
  data_sources: "Data sources",
  data_sinks: `Data sinks`,
  payment_gateways: `Payment Gateways`,
  disconnected_successfully: `{{erpName}} disconnected successfully`,
  connected_successfully: `{{erpName}} connection request sent`,

  last_sync_date: `Last sync date`,
  sync_now: `Sync now`,
  there_were_some_issues_encountered_during_the_synchronization_process: `There were some issues encountered during the syncing process`,
  enable_vendor_sync_for_supplier_portal: `Enable vendor sync for supplier portal`,
  entities_being_synced: `Entities being synced`,
  enable_po_sync_for_discrepancy: `Enable PO sync for discrepancy`,
  add_single_or_multiple_api_keys_by_pressing_enter: `Add single / multiple API keys by pressing 'Enter' after each one.`,
  disconnect_integration: `Disconnect {{integration}}`,
  disconnect_integration_warning: `Are you sure you want to disconnect {{integration}}, once disconnected all configurations will be lost`,
  ...holdedConfiguration,
  ...businessCentralConfiguration,
  ...netSuiteConfiguration,
  ...fourRetailConfiguration,
  ...sage,
};

export default integration;
