import numeral from "numeral";

// ----------------------------------------------------------------------

export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00");
}

export function fPercent(number: number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format("0.00a").replace(".00", "");
}

export function fData(number: string | number) {
  return numeral(number).format("0.0 b");
}

export function formatCurrency(number: string | number, format: string) {
  return numeral(number).format(format);
}

export function formatIBAN(iban) {
  // Remove any non-alphanumeric characters
  const cleanIBAN = iban?.replace(/\s+/g, "");

  // Insert a space after every 4 characters
  return cleanIBAN?.match(/.{1,4}/g).join(" ");
}
