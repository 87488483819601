import { useRef } from "react";

const useDimentions = ({ imgHeight, imgWidth }) => {
  const heightRef = useRef(0);
  const widthRef = useRef(0);
  let rect = document.getElementById(`review-page`)?.getBoundingClientRect();
  let containerWidth = (rect?.width ?? 0) * 0.6;
  let objectWidth = imgWidth;
  let objectHeight = imgHeight;
  let widthScaleFactor = containerWidth / objectWidth;
  const width_ = objectWidth * widthScaleFactor;
  const height_ = objectHeight * widthScaleFactor;
  heightRef.current = height_;
  widthRef.current = width_;
  return {
    width: widthRef.current,
    height: heightRef.current,
  };
};

export default useDimentions;

export const getDimensions = (
  imgHeight: number,
  imgWidth: number,
  fullWidth: boolean | undefined = false
) => {
  let height = 0;
  let width = 0;
  let rect = document.getElementById(`review-page`)?.getBoundingClientRect();
  let containerWidth = (rect?.width ?? 0) * (fullWidth ? 1 : 0.6) - 80;
  let objectWidth = imgWidth;
  let objectHeight = imgHeight;
  let widthScaleFactor = containerWidth / objectWidth;
  const width_ = objectWidth * widthScaleFactor;
  const height_ = objectHeight * widthScaleFactor;
  height = height_;
  width = width_;
  return {
    width,
    height,
  };
};

export function swapDimensions(
  originalWidth: number,
  originalHeight: number,
  rotationAngle: number
): { width: number; height: number } {
  let width = originalWidth;
  let height = originalHeight;

  if (rotationAngle === 90 || rotationAngle === 270) {
    // Swap width and height for 90° or 270° rotations
    width = originalHeight;
    height = originalWidth;
  }

  return { width, height };
}
