import { ReactElement } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  BreadcrumbsProps,
  Link,
  Breadcrumbs as MUIBreadcrumbs,
  Typography,
} from "@mui/material";
import useDefaultQuery from "src/pages/Documents/Finance/hooks/useDefaultQuery";

// ----------------------------------------------------------------------

type TLink = {
  href?: string;
  name: string;
  icon?: ReactElement;
  navigateBack?: boolean;
};

export interface Props extends BreadcrumbsProps {
  links: TLink[];
  activeLast?: boolean;
}

export default function Breadcrumbs({
  links,
  activeLast = false,
  ...other
}: Props) {
  const currentLink = links[links.length - 1].name;

  const { path: documentPath, comparePaths } = useDefaultQuery();
  const listDefault = links.map((link) => {
    return (
      <LinkItem
        key={link.name}
        link={
          comparePaths(link.href, documentPath)
            ? { ...link, href: documentPath }
            : link
        }
      />
    );
  });
  const listActiveLast = links.map((link) => (
    <div key={link.name}>
      {link.name !== currentLink ? (
        <LinkItem
          link={
            comparePaths(link.href, documentPath)
              ? { ...link, href: documentPath }
              : link
          }
        />
      ) : (
        <Typography
          variant="body2"
          sx={{
            maxWidth: 260,
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: "text.disabled",
            textOverflow: "ellipsis",
          }}
        >
          {currentLink}
        </Typography>
      )}
    </div>
  ));

  return (
    <MUIBreadcrumbs
      separator={
        <Box
          component="span"
          sx={{
            width: 4,
            height: 4,
            borderRadius: "50%",
            bgcolor: "text.disabled",
          }}
        />
      }
      {...other}
    >
      {activeLast ? listDefault : listActiveLast}
    </MUIBreadcrumbs>
  );
}

// ----------------------------------------------------------------------

type LinkItemProps = {
  link: TLink;
};

function LinkItem({ link }: LinkItemProps) {
  const { href, name, icon, navigateBack } = link;
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (navigateBack) {
      event.preventDefault(); // Prevent default link behavior
      if (window.history.length > 1) {
        navigate(-1); // Navigate back
      } else {
        navigate(href as string);
      }
    }
  };

  return (
    <Link
      key={name}
      variant="body2"
      component={RouterLink}
      // href={}
      to={navigateBack ? "#" : href || "#"}
      onClick={handleClick}
      sx={{
        lineHeight: 2,
        display: "flex",
        alignItems: "center",
        color: "text.primary",
        "& > div": { display: "inherit" },
      }}
    >
      {icon && (
        <Box sx={{ mr: 1, "& svg": { width: 20, height: 20 } }}>{icon}</Box>
      )}
      {name}
    </Link>
  );
}
