import DeselectIcon from "@mui/icons-material/Deselect";
import DoneIcon from "@mui/icons-material/Done";
import HighlightAltOutlinedIcon from "@mui/icons-material/HighlightAltOutlined";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import ChevronLeftIcon from "src/assets/icon/chevron/left";
import ChevronRightIcon from "src/assets/icon/chevron/right";
import InfoIcon from "src/assets/icon/info";
import ExpandLeftIcon from "src/assets/icon/menu/expand-left";
import { ReactComponent as RotateIcon } from "src/assets/icon/rotate.svg";
import SearchMinusIcon from "src/assets/icon/search/minus";
import useLocales from "src/hooks/useLocales";
import SearchPlusIcon from "./../../../assets/icon/search/plus";

const ReviewToolbarPanel = ({
  pages,
  combineSelectedAreas,
  setStayMultiHighlighted,
  editMode,
  setEditMode,
  setAlreadyLabelledAreas,
  resetState,
  isScreen,
  handleScreenBool,
  zoomIn,
  zoomOut,
  rotateMap,
  ...props
}) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const t = (key: string): string => translate(`reviewToolbar.${key}`);
  const { palette } = theme;
  const iconColor = palette?.primary?.light;
  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& svg": { m: 1.5 },
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                marginRight: 1,
                marginLeft: 2,
                fontWeight: 400,
                color: palette?.primary?.light,
                cursor: "pointer",
                "&:hover": { color: "black" },
              }}
            >
              {pages}
            </Typography>
            <Tooltip title={t("previous_page")}>
              <IconButton
                size="small"
                sx={{
                  marginRight: 1,
                  marginLeft: 1,
                  height: "30px",
                  width: "30px",
                  color: palette?.grey["400"],
                  bgcolor: palette?.grey["300"],
                  cursor: "pointer",
                  "&:hover": { color: "black" },
                }}
                onClick={() => {
                  props.previous();
                }}
              >
                <ChevronLeftIcon
                  height={18}
                  width={18}
                  color={theme.palette.primary.light}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("next_page")}>
              <IconButton
                size="small"
                sx={{
                  height: "30px",
                  width: "30px",
                  color: iconColor,
                  bgcolor: palette?.grey["300"],
                  cursor: "pointer",
                  "&:hover": { color: "black" },
                }}
                onClick={() => {
                  props.next();
                }}
              >
                <ChevronRightIcon
                  height={18}
                  width={18}
                  color={theme.palette.primary.light}
                />
              </IconButton>
            </Tooltip>
            <Divider
              orientation="vertical"
              sx={{
                height: "20px",
                width: "20px",
                color: iconColor,
                transform: "rotate(270deg)",
              }}
            />
            <Tooltip title={t("zoomin")}>
              <IconButton
                size="small"
                sx={{
                  marginRight: 1,
                  height: "30px",
                  width: "30px",
                  color: iconColor,
                  bgcolor: palette?.grey["300"],
                  cursor: "pointer",
                  "&:hover": { color: "black" },
                }}
                onClick={() => {
                  zoomIn();
                }}
              >
                <SearchPlusIcon height={18} width={18} color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("zoomout")}>
              <IconButton
                size="small"
                sx={{
                  height: "30px",
                  width: "30px",
                  color: iconColor,
                  cursor: "pointer",
                  bgcolor: palette?.grey["300"],
                  "&:hover": { color: "black" },
                }}
                onClick={() => {
                  zoomOut();
                }}
              >
                <SearchMinusIcon height={18} width={18} color="primary" />
              </IconButton>
            </Tooltip>
            <Divider
              orientation="vertical"
              sx={{
                height: "20px",
                width: "20px",
                color: iconColor,
                transform: "rotate(270deg)",
              }}
            />
            {editMode ? (
              <>
                <Tooltip title={t("deselect_multiple")}>
                  <IconButton
                    size="small"
                    sx={{
                      marginRight: 1,
                      height: "30px",
                      width: "30px",
                      color: iconColor,
                      bgcolor: palette?.grey["300"],
                      cursor: "pointer",
                      "&:hover": { color: "black" },
                    }}
                    onClick={() => {
                      setStayMultiHighlighted(
                        (stayMultiHighlighted: boolean) => !stayMultiHighlighted
                      );
                      setEditMode((editMode: boolean) => !editMode);
                      setAlreadyLabelledAreas(new Map());
                      resetState();
                    }}
                  >
                    <DeselectIcon
                      sx={{
                        height: "15px",
                        iconColor,
                        cursor: "pointer",
                        "&:hover": { color: "black" },
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("done")}>
                  <IconButton
                    size="small"
                    sx={{
                      height: "30px",
                      width: "30px",
                      color: iconColor,
                      bgcolor: palette?.grey["300"],
                      cursor: "pointer",
                      "&:hover": { color: "black" },
                    }}
                    onClick={() => {
                      combineSelectedAreas();
                    }}
                  >
                    <DoneIcon
                      sx={{
                        height: "15px",
                        color: iconColor,
                        cursor: "pointer",
                        "&:hover": { color: "black" },
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <Tooltip title={t("select_multiple")}>
                <IconButton
                  size="small"
                  sx={{
                    height: "30px",
                    width: "30px",
                    color: iconColor,
                    bgcolor: palette?.grey["300"],
                    cursor: "pointer",
                    "&:hover": { color: "black" },
                  }}
                  onClick={() => {
                    setStayMultiHighlighted(
                      (stayMultiHighlighted: boolean) => !stayMultiHighlighted
                    );
                    setEditMode((editMode: boolean) => !editMode);
                  }}
                >
                  <HighlightAltOutlinedIcon
                    sx={{
                      height: "15px",
                      color: iconColor,
                      cursor: "pointer",
                      "&:hover": { color: "black" },
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}{" "}
            <Divider
              orientation="vertical"
              sx={{
                height: "20px",
                width: "20px",
                color: iconColor,
                transform: "rotate(270deg)",
              }}
            />
            <Tooltip title={t("Rotate_document")}>
              <IconButton
                size="small"
                sx={{
                  marginRight: 2,
                  height: "30px",
                  width: "30px",
                  bgcolor: palette?.grey["300"],
                  cursor: "pointer",
                  "&:hover": { color: "black" },
                }}
                onClick={() => {
                  rotateMap(90);
                }}
              >
                <span>
                  <RotateIcon
                    style={{
                      stroke: iconColor,
                      height: "15px",
                      cursor: "pointer",
                      color: iconColor,
                    }}
                    stroke={iconColor}
                    height={20}
                    width={20}
                  />
                </span>
              </IconButton>
            </Tooltip>
          </Box>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              handleScreenBool();
            }}
            sx={{ marginLeft: "auto", my: 1 }}
            startIcon={
              <InfoIcon
                customColor={theme.palette.primary.light}
                height={18}
                width={18}
              />
            }
            endIcon={
              <Box
                sx={{
                  lineHeight: 0,
                  transition: (theme) =>
                    theme.transitions.create("transform", {
                      duration: theme.transitions.duration.shorter,
                    }),
                  ...(!isScreen && {
                    transform: "rotate(180deg)",
                  }),
                }}
              >
                <ExpandLeftIcon
                  color={theme.palette.primary.light}
                  height={"20px"}
                  width={"20px"}
                />
              </Box>
            }
          >
            {!isScreen ? t("hide_info") : t("show_info")}
          </Button>
        </Box>
        <Divider
          sx={{
            color: palette?.grey["400"],
            mb: 1,
          }}
        />
      </Grid>
    </>
  );
};

export default ReviewToolbarPanel;
