export const netSuiteConfiguration = {
  netsuite_docCls_actCls_required:
    "Netsuite document class and account class are required",
  netsuite_integration_unsupported: `Netsuite integration is not supported`,
  netsuite_subsidiaryid_required: `Netsuite subsidiary id is required`,
  netsuite_vendorid_required: `Netsuite vendor id is required`,
  netsuite_items_required: `Netsuite items are required`,
  netsuite_itemid_or_acctid_required: `Netsuite item id or account id is required (only one should be selected)`,
  netsuite_item_qty_required: `Netsuite item quantity is required`,
  netsuite_item_unitprice_required: `Netsuite item unit price is required`,
  netsuite_item_creation_not_supported: "Item creation not supported",
  netsuite_tbainfo_required: "TBA information is required",
  netsuite_custom_collection_id: "Netsuite custom collection id",
  netsuite_custom_field_id: "Netsuite custom field id",
  netsuite_data_type: "Netsuite data type",
  netsuite_custom_field_id_is_required: "Netsuite custom field ID is required",
  netsuite_data_type_is_required: "Netsuite data type is required",
};
