import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { WorkflowHistory } from "src/@types/workflows/workflowHistory";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import { ReactComponent as HistoryIcon } from "src/assets/icon/history.svg";
import { GetAsync } from "src/common/httpRequests";
import { EmptyDataSvg } from "src/components/EmptyContent";
import ErrorScreen from "src/components/ErrorScreen";
import { API_GATEWAY } from "src/constants/settings";
import useLocales from "src/hooks/useLocales";
import TimelineTileSkeleton from "src/pages/Documents/Finance/history/historySkeleton";
import WorkflowHistoryDetails from "./WorkflowHistoryDetails";

interface HistoryDrawerProps {
  openDrawer: boolean;
  handleDrawerClose: VoidFunction;
  id: string;
}

const HistoryDrawer: React.FC<HistoryDrawerProps> = (props) => {
  const { openDrawer, handleDrawerClose, id } = props;
  const { translate } = useLocales();
  const theme = useTheme();
  const [state, setState] = useState({
    isLoading: false,
    history: {} as WorkflowHistory,
    errorMsg: "",
  });

  const fetchWorkflowHistory = async () => {
    setState({ ...state, isLoading: true });
    await GetAsync(`${API_GATEWAY}/api/workflowservice/workflows/${id}/history`)
      .then((res) => {
        if (res.status === 200) {
          setState({ ...state, isLoading: false, history: res.data });
        } else {
          setState({ ...state, isLoading: false, errorMsg: res.data.Detail });
        }
      })
      .catch((err) => {
        console.log("Error", err);
        setState({ ...state, isLoading: false, errorMsg: err.error });
      });
  };

  useEffect(() => {
    fetchWorkflowHistory();
  }, []);
  const {
    errorMsg,
    history: { workflowHistoryDetails },
    isLoading,
  } = state;
  return (
    <Drawer
      open={openDrawer}
      variant="persistent"
      anchor="right"
      PaperProps={{
        sx: {
          width: {
            xs: "100%",
            sm: 500,
            md: 500,
            lg: 500,
            xl: 500,
          },
          borderTopLeftRadius: "16px",
          borderBottomLeftRadius: "16px",
          bgcolor: "background.paper",
          transition: (theme) =>
            theme.transitions.create("width", {
              duration: theme.transitions.duration.standard,
            }),
          boxShadow: (theme) => theme.customShadows.z24,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 1,
          }}
        >
          <HistoryIcon stroke={theme.palette.action.active} />
          <Typography variant="h6">
            {translate("workflows.workflow_change_logs")}
          </Typography>
        </Box>
        <IconButton sx={{ width: 10, height: 8 }} onClick={handleDrawerClose}>
          <CloseModalButton />
        </IconButton>
      </Box>
      <Divider />
      <Box
        sx={{
          overflow: "auto",
          flex: 1,
        }}
      >
        {isLoading ? (
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
            <TimelineTileSkeleton key={`skeleton-${item}`} />
          ))
        ) : errorMsg ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ mt: 2 }}>
              <EmptyDataSvg width={"120"} height={"120"} />
            </Box>
            <Typography variant="body2" sx={{ mt: 2 }}>
              {translate(errorMsg)}
            </Typography>
          </Box>
        ) : (
          <Box>
            {workflowHistoryDetails?.length === 0 ? (
              <ErrorScreen
                message={"exportConfiguration.no_logs_found"}
                icon={<EmptyDataSvg width={"120"} height={"120"} />}
              />
            ) : (
              <Box>
                <WorkflowHistoryDetails
                  {...{ workflowHistoryDetails, isLoading }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default HistoryDrawer;
