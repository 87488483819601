import { useRef, useState } from "react";

const usePanning = ({ gridRef, height, width }) => {
  const content: HTMLElement | null = document.querySelector(".scroll-content");
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });
  const handlePanningStart = (r, e) => {
    if (!gridRef.current) return;
    const slider = gridRef.current;
    const startX = e.pageX - slider.offsetLeft;
    const startY = e.pageY - slider.offsetTop;
    const scrollLeft = slider.scrollLeft;
    const scrollTop = slider.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    setIsMouseDown(true);
    document.body.style.cursor = "grabbing";
  };
  const handlePanningStop = (r, e) => {
    setIsMouseDown(false);
    if (!gridRef.current) return;
    document.body.style.cursor = "default";
  };
  const handlePanning = (r, e) => {
    if (!isMouseDown || !gridRef.current) return;
    e.preventDefault();
    const slider = gridRef.current;
    const x = e.pageX - slider.offsetLeft;
    const y = e.pageY - slider.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    slider.scrollTop = mouseCoords.current.scrollTop - walkY;
  };
  const customTransform = (...args) => {
    let scaleLevel = args[2];
    const scaledWidth = width * scaleLevel;
    const scaledHeight = height * scaleLevel;
    if (content) {
      content.style.transform = `scale(${scaleLevel})`;
      content.style.width = `${scaledWidth}px`;
      content.style.height = `${scaledHeight}px`;
    }
    return `scale(${args[2]})`;
  };
  let panningProps = {
    onPanning: handlePanning,
    onPanningStart: handlePanningStart,
    onPanningStop: handlePanningStop,
    customTransform,
  };
  return {
    panningProps,
  };
};
export default usePanning;
