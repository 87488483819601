import { fourRetailConfiguration } from "./configuration/4Retail";
import { businessCentralConfiguration } from "./configuration/businessCentral";
import { holdedConfiguration } from "./configuration/holded";
import { netSuiteConfiguration } from "./configuration/netsuite";
import { sage } from "./configuration/sage";

const integration = {
  integrations_connected: `Integraciones conectadas`,
  integration_not_connected: `Aún no tienes ninguna integración conectada`,
  erp_connected: `ERP conectado`,
  new_integration_button_text: `Agregar una nueva integración`,
  add_integration: `Agregar una integración`,
  data_source_connected: `Fuente de datos conectada`,
  available_integrations: `Integraciones disponibles para conectar`,
  ERP: `ERP`,
  data_sources: "Fuentes de Datos",
  data_sinks: `receptores de datos`,
  payment_gateways: `Pasarelas de pago`,
  disconnected_successfully: `{{erpName}} Desconectado exitosamente`,
  connected_successfully: `{{erpName}} de conexión retenida enviada`,

  last_sync_date: `Fecha de última sincronización`,
  sync_now: `Sincronizar ahora`,
  there_were_some_issues_encountered_during_the_synchronization_process: `Se encontraron algunos problemas durante el proceso de sincronización`,
  entities_being_synced: `Entidades que se están sincronizando`,
  enable_vendor_sync_for_supplier_portal: `Habilitar la sincronización de proveedores para el portal de proveedores`,
  enable_po_sync_for_discrepancy: `Habilitar la sincronización de órdenes de compra para discrepancias`,
  add_single_or_multiple_api_keys_by_pressing_enter: `Agregue una o varias claves API presionando 'Enter' después de cada una.`,
  disconnect_integration: `Desconectar {{integration}}`,
  disconnect_integration_warning: `¿Está seguro de que desea desconectar {{integration}}? Una vez desconectada, se perderán todas las configuraciones.`,
  ...businessCentralConfiguration,
  ...holdedConfiguration,
  ...netSuiteConfiguration,
  ...fourRetailConfiguration,
  ...sage,
};

export default integration;
