import { alpha, MenuItem, Tooltip, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { WorkflowsEntity } from "src/@types/workflows/workflows";
import CopyIcon from "src/assets/icon/copy/CopyIcon";
import CrossIcon from "src/assets/icon/cross";
import DeleteIcon from "src/assets/icon/delete";
import EditIcon from "src/assets/icon/edit";
import FileIcon from "src/assets/icon/file";
import { ReactComponent as HistoryIcon } from "src/assets/icon/history.svg";
import TickIcon from "src/assets/icon/tick";
import Label from "src/components/Label";
import { TableMoreMenu } from "src/components/table";
import useLocales from "src/hooks/useLocales";
import { fDateTimeSuffix } from "src/utils/formatTime";
import { Action } from "../type";
import ApproversPopover from "./ApproversPopover";
import DeleteWorkflowModal from "./DeleteWorkflowModal";
import ViewWorkflow from "./viewWorkflow";

interface CustomCardProps {
  item: WorkflowsEntity;
  handleOpenAction: (action: Action, id?: string) => void;
  handleGetWorkflowAfterDelete: VoidFunction;
  handleDocumentsModal: (id: string) => void;
  handleHistoryDrawer: (id: string) => void;
  disabled?: boolean;
  disableApproval?: boolean;
}

export default function CustomCard({
  item,
  handleOpenAction,
  handleGetWorkflowAfterDelete,
  handleDocumentsModal,
  handleHistoryDrawer,
  disabled,
  disableApproval,
}: CustomCardProps) {
  const { translate } = useLocales();
  const theme = useTheme();
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [isDeleteWorkflowDialogVisible, setIsDeleteWorkflowDialogVisible] =
    useState(false);
  const [anchorElW, setAnchorElW] = useState<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleWPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElW(event.currentTarget);
  };

  const handleWPopoverClose = () => {
    setAnchorElW(null);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleCloseWorkflowDialog = () => {
    setIsDeleteWorkflowDialogVisible(false);
  };

  return (
    <Box
      sx={{
        borderRadius: "8px",
        width: 318,
        height: 190,
        border: `1px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
      }}
    >
      <DeleteWorkflowModal
        isShowModal={isDeleteWorkflowDialogVisible}
        showModalMethod={handleCloseWorkflowDialog}
        id={item?.id}
        handleGetWorkflowAfterDelete={handleGetWorkflowAfterDelete}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          bgcolor: "secondary.light",
          p: 1,
          pb: 0.5,
          mb: 1,
          borderTopLeftRadius: "7px",
          borderTopRightRadius: "7px",
        }}
      >
        <Tooltip title={item.name} placement="top">
          <Typography
            variant="h6"
            sx={{
              textOverflow: "ellipsis",
              width: 250,
              cursor: "pointer",
              height: 30,
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {item.name}
          </Typography>
        </Tooltip>
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                disabled={disableApproval}
                onClick={() => {
                  handleDocumentsModal(item.id);
                  handleCloseMenu();
                }}
              >
                <FileIcon
                  color={theme.palette.text.greyLight}
                  disableDimention
                />
                {translate("See documents related")}
              </MenuItem>
              <MenuItem
                disabled={disabled}
                onClick={() => {
                  handleOpenAction("edit", item.id);
                  handleCloseMenu();
                }}
              >
                <EditIcon
                  height={"22px"}
                  width={"22px"}
                  customColor={theme.palette.text.greyLight}
                  disableDimension
                />
                {translate("Edit workflow")}
              </MenuItem>
              {item.updatedOn && (
                <MenuItem
                  disabled={disabled}
                  onClick={() => {
                    handleHistoryDrawer(item.id);
                    handleCloseMenu();
                  }}
                >
                  <HistoryIcon stroke={theme.palette.action.active} />

                  {translate("view_change_logs")}
                </MenuItem>
              )}
              <MenuItem
                disabled={disabled}
                onClick={() => {
                  handleOpenAction("duplicate", item.id);
                  handleCloseMenu();
                }}
              >
                <CopyIcon
                  height={"22px"}
                  width={"22px"}
                  customColor={theme.palette.text.greyLight}
                  disableDimension
                />
                {translate("Duplicate workflow")}
              </MenuItem>
              <MenuItem
                disabled={disabled}
                onClick={() => {
                  setIsDeleteWorkflowDialogVisible(true);
                  handleCloseMenu();
                }}
              >
                <DeleteIcon
                  customColor={theme.palette.text.greyLight}
                  disableDimension
                />
                {translate("Delete workflow")}
              </MenuItem>
            </>
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
          py: 0.25,
        }}
      >
        <Typography variant="body2" sx={{ color: "text.greyLight" }}>
          {translate("Conditions configured")}
        </Typography>
        <Label
          sx={{
            bgcolor: "background.neutral",
            color: "text.greyLight",
            border: `1px solid ${theme.palette.text.disabled}`,
            p: 0.5,
            px: 1,
            fontSize: "12px",
            textTransform: "capitalize",
          }}
        >
          <Typography
            variant="body2"
            onMouseEnter={handleWPopoverOpen}
            onMouseLeave={handleWPopoverClose}
            sx={{ color: "text.greyLight", textTransform: "none" }}
          >
            {item.detail?.conditions?.length}{" "}
            {item.detail?.conditions?.length === 1
              ? translate("Condition")
              : translate("Conditions")}
          </Typography>
        </Label>
        <ViewWorkflow
          conditions={item?.detail?.conditions ?? []}
          anchorEl={anchorElW}
          handlePopoverClose={handleWPopoverClose}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
          py: 0.25,
          gap: 1,
        }}
      >
        <Typography variant="body2" sx={{ color: "text.greyLight" }}>
          {translate("Approvers")}
        </Typography>
        <Label
          sx={{
            bgcolor: "background.neutral",
            color: "text.greyLight",
            border: `1px solid ${theme.palette.text.disabled}`,
            p: 0.5,
            px: 1,
            fontSize: "12px",
            textTransform: "capitalize",
          }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Typography
            variant="body2"
            sx={{
              color: "text.greyLight",
              textTransform: "none",
              textOverflow: "ellipsis",
              cursor: "pointer",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {item.detail?.approvers?.length === 1
              ? item.detail?.approvers?.[0]
              : item.detail?.approvers?.length + " " + translate("Approver")}
          </Typography>
        </Label>
        <ApproversPopover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          handlePopoverClose={handlePopoverClose}
          approvers={item.detail?.approvers ?? []}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
          py: 0.25,
        }}
      >
        <Typography variant="body2" sx={{ color: "text.greyLight" }}>
          {translate("review_allowed")}
        </Typography>
        {item.allowReviewDocument ? (
          <TickIcon height={22} width={22} />
        ) : (
          <CrossIcon height={22} width={22} />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
          py: 0.25,
        }}
      >
        <Typography variant="body2" sx={{ color: "text.greyLight" }}>
          {translate("disable_approval")}
        </Typography>
        {item.approvalNotRequired ? (
          <TickIcon height={22} width={22} />
        ) : (
          <CrossIcon height={22} width={22} />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
          py: 0.25,
        }}
      >
        <Typography variant="body2" sx={{ color: "text.greyLight" }}>
          {translate("Created on")}&nbsp; {fDateTimeSuffix(item.createdOn)}
        </Typography>
      </Box>
    </Box>
  );
}
