const transactions = {
  transactions: `Transactions`,
  transaction: `Transaction`,
  no_transactions_found: `No transactions found`,
  sent_money_to: `Sent money to`,
  received_money_from: `Received money from`,
  transactedAt: `Transacted at`,
  not_available_testing_mode: `Not available in testing mode`,
  gateway_not_connected: `Payment gateway not connected`,
  transacted_by: `Transacted by`,
  transacted_on: `Transacted on`,
  status: `Status`,
  received: `Received`,
  sent: `Sent`,
};
export default transactions;
