import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import IconAndroid from "src/assets/icon/Andriod";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import CopyIcon from "src/assets/icon/copy/CopyIcon";
import { GetAsync } from "src/common/httpRequests";
import Loader from "src/components/loading/loader";
import { API_GATEWAY, MOBILE_APP_DOWNLOAD_URL } from "src/constants/settings";
import useLocales from "src/hooks/useLocales";
interface ModalProps {
  isShowModal: boolean;
  showModalMethod: VoidFunction;
  modalTitle: string;
  modalIllustration: any;
  modalContentText: string;
  dostEmail?: string;
}

export default function QRcodeUploadDialog(props: ModalProps) {
  const {
    isShowModal,
    showModalMethod,
    modalTitle,
    modalIllustration,
    modalContentText,
    dostEmail,
  } = props;
  const theme = useTheme();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("body");

  const [text, setText] = useState<string>("copy");
  const [icon, setIcon] = useState(<CopyIcon />);
  const descriptionElementRef = useRef<HTMLElement>(null);
  const [qrcode, setQrcode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isShowModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      setScroll("body");
    }
  }, [isShowModal]);
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const getQrcode = async () => {
    setLoading(true);
    GetAsync(`${API_GATEWAY}/api/documentservice/Document/GenerateQR`)
      .then((res) => {
        if (res.data.base64QR)
          setQrcode("data:image/png;base64," + res.data.base64QR);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isShowModal && qrcode === "") getQrcode();
  }, [isShowModal]);

  const handleTooltipOpen = (dostEmail) => {
    setText(`Copied`);
    setIcon(
      <TaskAltIcon sx={{ height: "30px", width: "24px" }} color="success" />
    );

    navigator?.clipboard?.writeText(dostEmail);

    setInterval(() => {
      setText(`copy`);
      setIcon(<CopyIcon />);
    }, 10000);
  };

  return (
    <div style={{ borderRadius: "90px" }}>
      <Dialog
        open={isShowModal}
        onClose={showModalMethod}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title responsive-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle
          id="scroll-dialog-title responsive-dialog-title"
          sx={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            pb: 2,
          }}
        >
          <Box>{translate("documents_screen.document_upload_via_qr")}</Box>
          <IconButton sx={{ width: 10, height: 8 }} onClick={showModalMethod}>
            <CloseModalButton />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          sx={{ textAlign: "center" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  bgcolor: "background.neutral",
                  borderRadius: "10px",
                  padding: "20px",
                }}
              >
                {qrcode !== "" ? (
                  <Box
                    sx={{
                      borderRadius: 1,
                      border: "1px solid #E4E6EB",
                    }}
                  >
                    <img
                      src={qrcode}
                      alt="qrcode"
                      style={{
                        width: "250px",
                        height: "250px",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "250px",
                      width: "250px",
                    }}
                  >
                    <Loader />
                  </Box>
                )}
              </Box>
              <Typography variant="body1" color="text.secondary">
                {translate(
                  "documents_screen.scan_the_qr_code_to_instantly_upload_your_documents_with_the_dost_app"
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                direction: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ maxWidth: "170px", ml: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    bgcolor: "background.neutral",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  {qrcode !== "" ? (
                    <Box
                      sx={{
                        borderRadius: 1,
                        border: "1px solid #E4E6EB",
                        width: "130px",
                        height: "130px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <QRCodeSVG
                        value={MOBILE_APP_DOWNLOAD_URL}
                        size={100}
                        style={{ marginTop: "12px" }}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "150px",
                        width: "150px",
                      }}
                    >
                      <Loader />
                    </Box>
                  )}
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {translate(
                    "documents_screen.scan_this_qr_code_to_download_dost_app"
                  )}
                </Typography>
              </Box>
              <Button
                onClick={() => window.open(MOBILE_APP_DOWNLOAD_URL, "_blank")}
                variant="outlined"
                size="small"
                startIcon={<IconAndroid color="primary" />}
                sx={{
                  textAlign: "left",
                  ml: 1,
                }}
              >
                <Typography variant="body2">
                  {translate(`Download App (Android)`)}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
