import { SetStateAction, createContext, useReducer, useState } from "react";
import { GetAsync } from "src/common/httpRequests";
import { API_GATEWAY } from "src/constants/settings";
import { updatePayloadFields } from "../hooks/useItemsTable";
import {
  DocumentFieldsExpectedValueConfigs,
  DocumentFieldsExpectedValues,
  TReviewContextType,
} from "../types/reviewContext";
import { ReviewResponse } from "../types/reviewResponse";

export const ReviewContext = createContext<TReviewContextType>({
  label: {
    labelColorMap: {},
    setLabelColorMap: (labelColorMap: any) => {},
  },
  reviewResponse: {
    state: {
      fields: [],
    },
    dispatch: (action: any) => {},
  },
  axis: {
    axis: {
      xAxis: null,
      yAxis: null,
      xAxisDialog: null,
      yAxisDialog: null,
    },
    setAxis: (axis: any) => {},
  },
  initial: {
    initialState: {
      fileId: "",
      fields: [],
      map: { name: "labels", areas: [] },
      metaData: [],
      confidenceArray: [],
      status: null,
    },
    setInitialState: (initialState: any) => {},
  },
  dimensions: {
    width: 0,
    height: 0,
    setHeight: (height: SetStateAction<number>) => {},
    setWidth: (width: SetStateAction<number>) => {},
    rotation: 0,
    setRotation: (rotation: SetStateAction<number>) => {},
  },
  base64String: {
    base64String: [],
    setBase64String: (base64String: SetStateAction<(string | null)[]>) => {},
    imageloading: [],
    setImageLoading: (imageloading: SetStateAction<boolean[]>) => {},
    fetchBase64String: async (
      fileId: string | undefined,
      pageNo: number,
      callback?: (response: any) => void
    ): Promise<string | null> => {
      return "";
    },
  },
  tag: {
    tagContentString: "",
    setTagContentString: (tagContentString: SetStateAction<string>) => {},
    getContentString: (pages: any[]) => "",
  },
  expectedValues: {
    documentFieldsExpectedValues: [],
    documentFieldsExpectedValueConfigs: [],
    setDocumentFieldsExpectedValueConfigs: (
      documentFieldsExpectedValues: SetStateAction<
        DocumentFieldsExpectedValueConfigs[]
      >
    ) => {},
    setDocumentFieldsExpectedValues: (
      documentFieldsExpectedValues: SetStateAction<
        DocumentFieldsExpectedValues[]
      >
    ) => {},
  },
});

export default function ReviewContextProvider({ children }) {
  const [labelColorMap, setLabelColorMap] = useState<any>({});
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [rotation, setRotation] = useState(0);
  const [base64String, setBase64String] = useState<(string | null)[]>([]);
  const [imageloading, setImageLoading] = useState<boolean[]>([]);
  const [documentFieldsExpectedValues, setDocumentFieldsExpectedValues] =
    useState<DocumentFieldsExpectedValues[]>([]);
  const [
    documentFieldsExpectedValueConfigs,
    setDocumentFieldsExpectedValueConfigs,
  ] = useState<DocumentFieldsExpectedValueConfigs[]>([]);
  const [tagContentString, setTagContentString] = useState<string>("");
  const reviewInitialState: ReviewResponse = {
    fields: [],
  };

  const reducer = (state: ReviewResponse, action: any) => {
    switch (action.type) {
      case "ADD_FIELD":
        let fields = updatePayloadFields(action.payload, state.fields);
        return {
          ...state,
          fields: fields,
        };
      case "REMOVE_FIELD":
        let fields_ = updatePayloadFields(action.payload, state.fields);
        return {
          ...state,
          fields: fields_,
        };
      case "REMOVE_TABLE":
        let fields__ = state.fields;
        let delete_name = action.payload.name;
        let index = fields__.findIndex((field_) => field_.name === delete_name);
        if (index !== -1) {
          let fieldToBeDeleted = fields__[index];
          fieldToBeDeleted.isRemoved = true;
          fieldToBeDeleted.itemsFields = [];
          fields__[index] = fieldToBeDeleted;
        } else {
          fields__.push({
            name: delete_name,
            value: null,
            pageNumber: null,
            confidence: null,
            isRemoved: true,
            isNew: false,
            itemsFields: [],
            boundingPolygon: null,
          });
        }
        return {
          ...state,
          fields: fields__,
        };
      case "SET_INITIAL_STATE":
        return {
          ...state,
          fields: action.payload,
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, reviewInitialState);

  const fetchBase64String = async (
    fileId: string | undefined,
    pageNo: number
  ) => {
    let baseString: string | null = "";
    let imageLoading = imageloading;
    imageLoading[pageNo - 1] = true;
    setImageLoading(imageLoading);
    if (base64String[pageNo - 1]) {
      baseString = base64String[pageNo - 1];
      baseString = await Promise.resolve(baseString);
      imageLoading[pageNo - 1] = false;
      setImageLoading(imageLoading);
      return baseString;
    } else {
      baseString = await GetAsync(
        `${API_GATEWAY}/api/documentservice/documentreview/${fileId}/base64?pageNumber=${pageNo}`
      ).then((response: any) => {
        imageLoading[pageNo - 1] = false;
        setImageLoading(imageLoading);
        return response?.data?.base64String;
      });
      return baseString;
    }
  };

  const getContentString = (pages: any[]) => {
    let contentString = "";
    pages.forEach((page) => {
      page.lines?.forEach((line) => {
        contentString += line.content + " ";
      });
    });
    return contentString;
  };

  const [axis, setAxis] = useState<{
    xAxis: null | number;
    yAxis: null | number;
    xAxisDialog: null | number;
    yAxisDialog: null | number;
  }>({
    xAxis: null,
    yAxis: null,
    xAxisDialog: null,
    yAxisDialog: null,
  });

  const [initialState, setInitialState] = useState<{
    fileId: string;
    fields: any[];
    map: {
      name: string;
      areas: any[];
    };
    metaData: any[];
    confidenceArray: any[];
    status: number | null;
  }>({
    fileId: "",
    fields: [],
    map: { name: "labels", areas: [] },
    metaData: [],
    confidenceArray: [],
    status: null,
  });

  return (
    <ReviewContext.Provider
      value={{
        label: { labelColorMap, setLabelColorMap },
        axis: { axis, setAxis },
        initial: { initialState, setInitialState },
        dimensions: {
          width,
          height,
          setHeight,
          setWidth,
          rotation,
          setRotation,
        },
        reviewResponse: { state, dispatch },
        base64String: {
          base64String,
          setBase64String,
          imageloading,
          setImageLoading,
          fetchBase64String,
        },
        expectedValues: {
          documentFieldsExpectedValues,
          setDocumentFieldsExpectedValues,
          documentFieldsExpectedValueConfigs,
          setDocumentFieldsExpectedValueConfigs,
        },
        tag: { tagContentString, setTagContentString, getContentString },
      }}
    >
      {children}
    </ReviewContext.Provider>
  );
}
