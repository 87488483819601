export const businessCentralConfiguration = {
  business_central_configurations: `Business Central configurations`,
  bc_integration_unsupported: `Business Central integration not supported`,
  bc_postprocessed_json_required: `Business Central post-processed JSON is required`,
  bc_docCls_actCls_required: `Business Central document class and account class is required`,
  bc_companyname_required: "Company name is required.",
  bc_customername_required: "Customer name is required.",
  bc_items_required: "Items are required.",
  bc_item_qty_required: "Item quantity is required.",
};
