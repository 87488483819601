import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { useField } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import { useEffect, useState } from "react";
import { AutocompleteMenuProps } from "src/@types/components/autocomplete";
import ChevronDown from "src/assets/icon/chevron/ChevronDown";
import MyDenseAutocomplete from "./denseForm/Autocomplete";
import MyErrorHelperText from "./denseForm/ErrorHelperText";
import MyHelperText from "./denseForm/HelperText";
import MyMenuItem from "./denseForm/MenuItem";
import MyInputLabel from "./form/InputLabel";
import NewTextField, {
  BootstrapInput,
  NewTextFormField,
} from "./form/TextField";

export function MyAutoComplete({
  options,
  label,
  displayKey,
  onChange,
  sx = {},
  InputLabelProps = {},
  ...props
}: any) {
  const [{ ...field }] = useField(props);
  return (
    <FormControl fullWidth variant="standard">
      {props.label && (
        <InputLabel
          htmlFor="demo-customized-textbox"
          sx={{
            fontSize: "16px",
            fontWeight: 700,
            transform: "translate(0, 1.5px) scale(0.75)",
            color: "action.active",
            ...sx,
            ...InputLabelProps,
          }}
        >
          {props.label}
        </InputLabel>
      )}
      <Autocomplete
        // disablePortal
        size="small"
        id="combo-box-demo"
        options={options}
        sx={{ width: 315, paddingTop: 6 }}
        defaultValue={field?.value}
        getOptionLabel={(option) => option[displayKey]}
        onChange={onChange}
        renderInput={({ ...params }) => {
          const { InputLabelProps, InputProps, inputProps, ...rest } = params;
          return (
            <BootstrapInput
              endAdornment={params.InputProps?.endAdornment}
              ref={InputProps?.ref}
              {...params}
            />
          );
        }}
      />
    </FormControl>
  );
}

export const MyTextField = ({
  placeholder,
  sx,
  label,
  variant,
  onChange,
  onClick,
  value,
  InputProps,
  type,
  autoFillOff,
  className = "",
  required,
  multiple,
  rows,
  onBlur,
  error,
  disabled,
  helperText,
  startAdornment,
  endAdornment,
  inputProps = { min: 0, step: ".0001" },
  ...props
}: any) => {
  const [field, meta] = useField(props);
  const errorText = error ?? (meta.error && meta.touched ? meta.error : "");

  return (
    <NewTextField
      size="small"
      fullWidth
      multiline={multiple || false}
      rows={rows || 0}
      sx={sx}
      type={type}
      onClick={onClick}
      disabled={disabled}
      label={label}
      placeholder={placeholder}
      autoComplete={autoFillOff && "new-password"}
      required={required}
      helperText={errorText}
      error={!!errorText}
      inputProps={inputProps}
      // InputProps={InputProps}
      className={className}
      {...field}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      onBlur={onBlur}
      onKeyUp={onChange}
    />
  );
};
export const MyTextFormField = ({
  placeholder,
  sx,
  label,
  variant,
  onChange,
  onClick,
  value,
  InputProps,
  type,
  autoFillOff,
  className = "",
  required,
  multiple,
  rows,
  onBlur,
  error,
  disabled,
  helperText,
  inputProps = { min: 0, step: ".0001" },
  ...props
}: any) => {
  const [field, meta] = useField(props);
  const errorText = error ?? (meta.error && meta.touched ? meta.error : "");
  return (
    <NewTextFormField
      size="small"
      fullWidth
      multiline={multiple || false}
      rows={rows || 0}
      sx={sx}
      type={type}
      onClick={onClick}
      disabled={disabled}
      label={label}
      placeholder={placeholder}
      autoComplete={autoFillOff && "new-password"}
      required={required}
      helperText={errorText}
      error={!!errorText}
      inputProps={inputProps}
      InputProps={InputProps}
      className={className}
      {...field}
      onBlur={onBlur}
      onKeyUp={onChange}
      {...props}
    />
  );
};

export const MyDateField = ({
  name,
  label,
  required,
  setFieldValue,
  val,
  currentDate,
  disabled = false,
  onBlur,
  id,
}: {
  name: string;
  label?: string;
  required?: boolean;
  setFieldValue: any;
  val: any;
  currentDate?: boolean;
  disabled?: boolean;
  onBlur?: any;
  id?: string;
}) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 50);
  return (
    <div className="form-group">
      <TextField
        name={name}
        size="small"
        fullWidth
        disabled={disabled}
        type="date"
        id={id}
        variant="outlined"
        required={required}
        label={label}
        defaultValue={format(new Date(val), "yyyy-mm-dd")}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => {
          setFieldValue(
            name,
            format(
              new Date((e.target as HTMLInputElement).valueAsNumber),
              "YYYY-MM-DD"
            )
          );
        }}
        onBlur={onBlur}
        inputProps={{
          // only needs the first 16 characters in the date string
          min: currentDate ? currentDate : "",
          max: date.toISOString().slice(0, 10),
        }}
      />
    </div>
  );
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row" as "row",
  flexWrap: "wrap" as "wrap",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box" as "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
export function MuiAutocomplete({
  displayKey,
  getOptionDisabled,
  setFieldValue,
  ...props
}: any) {
  const {
    name,
    valueKey,
    data,
    placeholder,
    label,
    multiple,
    required,
    itemDisplayOpts,
    val,
    key,
    onChange,
    parentCallbackOpt,
    disabled = false,
    initialCall = false,
  } = props;
  // const classes = useStyles();
  const [field, meta] = useField(props);
  const [selectedItems, setSelectedItems] = useState(
    !multiple
      ? data?.find((v) =>
          val ? (val?.id || val)?.toString() === v.id?.toString() : val
        )
      : val
      ? val
      : []
  );
  useEffect(() => {
    if (setFieldValue && name && val) {
      if (valueKey) {
        setFieldValue(name, val[valueKey]);
        if (parentCallbackOpt && val && initialCall) {
          parentCallbackOpt(val, name, setFieldValue);
        }
      } else {
        setFieldValue(name, val);
        if (parentCallbackOpt && val && initialCall) {
          parentCallbackOpt(val, name, setFieldValue);
        }
      }
    }
  }, [val]);
  return (
    <div>
      <div className="form-group">
        {label && (
          <label htmlFor={name} className="form-label">
            {label}
          </label>
        )}
        <Autocomplete
          size={"small"}
          disablePortal // name={name}
          sx={{ width: 315, paddingTop: 2 }}
          key={key}
          multiple={multiple}
          options={data}
          filterSelectedOptions
          disabled={disabled}
          getOptionLabel={(option) => option[displayKey]}
          getOptionDisabled={getOptionDisabled}
          defaultValue={selectedItems ? selectedItems : null}
          renderInput={(params) => (
            <NewTextField
              helperText={meta.touched && meta.error}
              error={!!meta.touched && !!meta.error}
              // className={classes.root}
              {...field}
              // {...props}
              {...params}
              fullWidth
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
              }}
              label={placeholder}
              variant="outlined"
              required={required}
            />
          )}
          onChange={(e, value) => {
            setFieldValue(
              name,
              value ? (valueKey ? value[valueKey] : value) : value
            );
            setSelectedItems(
              value ? (valueKey ? value[valueKey] : value) : value
            );
            if (onChange) {
              onChange(e, value);
            }
            if (parentCallbackOpt && value) {
              parentCallbackOpt(
                value ? (valueKey ? value[valueKey] : value) : value,
                name,
                setFieldValue
              );
            }
          }}
        />
      </div>
      {itemDisplayOpts && (
        <aside style={thumbsContainer}>
          {itemDisplayOpts.showItems && selectedItems ? (
            selectedItems.map((o) => {
              return (
                <div key={o.id} style={{ width: "10%" }}>
                  {itemDisplayOpts.showImage ? (
                    <div style={thumb} key={o[displayKey]}>
                      <div style={thumbInner}>
                        <img
                          src={
                            o[itemDisplayOpts.imgKey] &&
                            o[itemDisplayOpts.imgKey].path
                              ? o[itemDisplayOpts.imgKey].path
                              : "avatars/8.jpg"
                          }
                          style={img}
                          alt=""
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div style={{ marginRight: 5 }}>{o[displayKey]}</div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </aside>
      )}
    </div>
  );
}
interface MenuItemProps {
  value: string;
  label: string;
  disabled?: boolean;
}

export const MySelectField = ({
  placeholder,
  onChange,
  sx,
  onBlur,
  inputProps,
  label,
  options,
  required,
  error,
  startAdornment,
  multiple = false,
  disabled = false,
  ...props
}: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const theme = useTheme();

  return (
    <Box>
      <Select
        id="customized-textbox"
        displayEmpty
        error={error ? error : !!errorText}
        {...field}
        inputProps={inputProps}
        labelId={props.name}
        input={
          <NewTextField
            label={label}
            startAdornment={startAdornment}
            error={error}
            helperText={errorText}
          />
        }
        sx={{
          "& .MuiInputBase-input": {
            position: "relative",
            backgroundColor: "background.paper",
            ...(startAdornment
              ? {
                  padding: "5px 26px 5px 32px",
                }
              : {
                  padding: "5px 26px 5px 10px",
                }),
            alignItems: "center",
            ...((error || !!errorText) && {
              backgroundColor: theme.palette.error.light,
              borderColor: theme.palette.error.dark,
            }),
          },
        }}
        multiple={multiple}
        onChange={(e) => {
          field.onChange(e);
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={onBlur}
        disabled={disabled}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}
        {options
          .filter((o) => !o.disabled)
          .map((o) => {
            return (
              <MenuItem
                sx={{
                  fontSize: 12,
                }}
                key={o.value}
                value={o.value}
              >
                {o.label}
              </MenuItem>
            );
          })}
      </Select>
    </Box>
  );
};
export const MySelectField_ = ({
  placeholder,
  onChange,
  sx,
  onBlur,
  label,
  options,
  required,
  errorText,
  value,
  startAdornment,
  endAdornment,
  disabled = false,
  ...props
}: any) => {
  return (
    <div className="form-group">
      <FormControl
        variant="outlined"
        size="small"
        fullWidth
        required={required}
      >
        {label && (
          <InputLabel sx={sx} id={props.name} className="form-label">
            {label}
          </InputLabel>
        )}
        <Select
          error={!!errorText}
          labelId={props.name}
          label={label}
          value={value}
          {...props}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
          }}
          onBlur={onBlur}
          disabled={disabled}
          input={
            <NewTextField
              label={label}
              startAdornment={startAdornment}
              endAdornment={endAdornment}
            />
          }
        >
          {options
            .filter((o) => !o.disabled)
            .map((o) => {
              return (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              );
            })}
        </Select>
        {errorText && (
          <span
            style={{
              color: "#FF4842",
              fontSize: "12px",
              marginLeft: "10px",
              marginTop: "10px",
            }}
            className="form-label"
          >
            {errorText}
          </span>
        )}
      </FormControl>
    </div>
  );
};

export const MyPhoneField = ({
  placeholder,
  onChange,
  onBlur,
  label,
  required,
  disabled = false,
  ...props
}: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <MuiPhoneNumber
      size="small"
      label={label}
      {...props}
      defaultCountry={"es"}
      {...field}
      onChange={onChange}
      onBlur={onBlur}
      variant="outlined"
      error={!!errorText}
      helperText={errorText}
      fullWidth
    />
  );
};

export function MyAutoCompleteField({
  options,
  ref,
  disabled,
  label,
  displayKey,
  val,
  onChange,
  ...props
}: any) {
  const [field, meta] = useField(props);
  const [inputValue, setInputValue] = useState(val?.[displayKey] ?? "");
  useEffect(() => {
    if (val == null) setInputValue("");
    else setInputValue(val?.[displayKey]);
  }, [val]);
  return (
    <Autocomplete
      ref={ref}
      // disablePortal
      size="small"
      multiple={props.multiple}
      id={props.name}
      clearOnBlur={true}
      options={options}
      disabled={disabled}
      openText="Open"
      inputValue={inputValue}
      onInputChange={(event, newInputValue, reason) => {
        setInputValue(newInputValue);
      }}
      defaultValue={val ?? field?.value}
      getOptionLabel={(option) => option[displayKey]}
      onChange={onChange}
      renderInput={({ inputProps, ...params }) => (
        <>
          {" "}
          <NewTextField
            error={!!meta.error}
            inputProps={{ ...inputProps, min: 0 }}
            label={label}
            {...params}
            value={field?.value?.[props.name] ?? ""}
          />
        </>
      )}
    />
  );
}

export const MyRadioGroup = ({
  options,
  label,
  disabled = false,
  onChange,
  ...props
}: any) => {
  const [field] = useField(props);
  return (
    <Box>
      {label && <MyInputLabel>{label}</MyInputLabel>}
      <RadioGroup aria-label={props.name} row {...field}>
        {options?.map((o) => (
          <FormControlLabel
            key={o.value}
            value={o.value}
            control={<Radio size="small" color="primary" />}
            label={o.label}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

type MyAutoCompleteProps = {
  options: any[];
  displayKey: string;
  label?: string;
  val?: any;
  error?: boolean;
  errorText?: string | undefined;
  helperText?: string;
  onChange?: (e: any, v: any) => void;
  onBlur?: (e: any) => void;
  disabled?: boolean;
  name: string;
  ref?: any;
  style?: any;
  t?: (key: string) => string;
  itemDisplayOpts?: {
    showImage?: boolean;
    imgKey?: string;
    showColor?: boolean;
    colorKey?: string | "strokeColor";
  };
  inputValue?: string;
  sx?: any;
  placeholder?: string;
  optionTooltipProps?: any;
} & AutocompleteMenuProps;

export function AutoComplete({
  options,
  ref,
  t,
  itemDisplayOpts,
  error,
  errorText,
  disabled,
  onBlur,
  label,
  style,
  displayKey,
  val,
  sx,
  onChange,
  optionTooltipProps,
  menuOptionProps = {
    showValueKey: false,
  },
  ...props
}: MyAutoCompleteProps) {
  const [field] = useField(props);
  const [inputValue, setInputValue] = useState(
    val ? (val?.[displayKey] != undefined ? val?.[displayKey] : val) : ""
  );
  useEffect(() => {
    if (val == null) setInputValue("");
    else if (val) {
      options?.forEach((o) => {
        if (o.value === val) setInputValue(o[displayKey]);
      });
    }
  });

  return (
    <MyDenseAutocomplete
      ref={ref}
      id={props.name}
      placeholder={props.placeholder}
      options={options}
      disabled={disabled}
      error={error}
      errorText={errorText}
      onBlur={onBlur}
      popupIcon={<ChevronDown height={18} width={30} />}
      renderOption={(props: any, option: any, state: object) => (
        <MyMenuItem
          {...{
            option,
            displayKey,
            itemDisplayOpts,
            t,
            ...props,
            optionTooltipProps,
            ...menuOptionProps,
          }}
        />
      )}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) =>
        option[displayKey] === value[displayKey] || option[displayKey] === value
      }
      onInputChange={(event, newInputValue, reason) => {
        setInputValue(newInputValue);
      }}
      defaultValue={val ?? field?.value}
      getOptionLabel={(option) =>
        option?.[displayKey]
          ? t?.(option[displayKey]) ?? option[displayKey]
          : ""
      }
      onChange={onChange}
    />
  );
}

export function ReviewAutoComplete({
  options,
  ref,
  t,
  itemDisplayOpts,
  error,
  errorText,
  disabled,
  onBlur,
  label,
  style,
  displayKey,
  placeholder,
  helperText,
  val,
  sx,
  onChange,
  ...props
}: MyAutoCompleteProps) {
  const [field] = useField(props);
  const [inputValue, setInputValue] = useState(
    val?.[displayKey] == undefined ? val?.[displayKey] : ""
  );
  useEffect(() => {
    if (val == null) setInputValue("");
  });
  const theme = useTheme();

  return (
    <Autocomplete
      ref={ref}
      disablePortal
      size="small"
      id={props.name}
      clearOnBlur={true}
      options={options}
      disabled={disabled}
      openText="Open"
      onBlur={onBlur}
      renderOption={(props: any, option: any, state: object) => (
        <span key={props.id}>
          {itemDisplayOpts?.showImage && itemDisplayOpts.imgKey && (
            <span key={option.id} className="c-avatar">
              <img
                src={
                  option[itemDisplayOpts.imgKey] &&
                  option[itemDisplayOpts.imgKey].path
                    ? option[itemDisplayOpts.imgKey].path
                    : "avatars/8.jpg"
                }
                className="c-avatar-img"
              />
            </span>
          )}
          <span {...props} style={{ marginLeft: 10, fontSize: "13px" }}>
            {itemDisplayOpts?.showColor && (
              <div
                style={{
                  height: "22px",
                  width: "22px",
                  background:
                    option[itemDisplayOpts.colorKey ?? "strokeColor"] ??
                    "whitesmoke",
                  borderRadius: "50%",
                  display: "inline-block",
                  marginRight: "0.2rem",
                }}
              ></div>
            )}
            {t?.(option[displayKey]) ?? option[displayKey]}
          </span>
        </span>
      )}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) =>
        option[displayKey] === value[displayKey]
      }
      onInputChange={(event, newInputValue, reason) => {
        setInputValue(newInputValue);
      }}
      sx={sx ? sx : { width: 315, paddingTop: 1, paddingBottom: 1 }}
      defaultValue={val ?? field?.value}
      getOptionLabel={(option) =>
        option?.[displayKey]
          ? t?.(option[displayKey]) ?? option[displayKey]
          : ""
      }
      onChange={onChange}
      popupIcon={<ChevronDown height={18} width={30} />}
      renderInput={({ ...params }) => {
        const { inputProps, ...rest } = params;
        let { InputLabelProps, InputProps, ...params_ } = params;
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              alignItems: "flex-start",
              p: 0,
              gap: "4px",
            }}
          >
            {label && (
              <InputLabel
                htmlFor="demo-customized-textbox"
                sx={{
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "action.active",
                }}
              >
                {label}
              </InputLabel>
            )}
            <BootstrapInput
              ref={InputProps?.ref}
              {...params_}
              endAdornment={InputProps?.endAdornment}
              placeholder={placeholder}
              sx={{
                "& .MuiInputBase-input": {
                  ...(!!error && {
                    backgroundColor: theme.palette.error.light,
                    borderColor: theme.palette.error.dark,
                    color: theme.palette.error.dark,
                    "&::placeholder": {
                      opacity: 1,
                      color: theme.palette.error.dark,
                    },
                    "&:focus": {
                      borderColor: theme.palette.error.dark,
                      boxShadow: `0 0 0 0.01rem ${theme.palette.error.dark}`,
                    },
                  }),
                },
              }}
            />
            {error ? (
              <MyErrorHelperText error={!!error} helperText={errorText} />
            ) : helperText ? (
              <MyHelperText helperText={helperText} sx={{ py: 0 }} />
            ) : null}
          </Box>
        );
      }}
    />
  );
}

export function RegisterAutoComplete({
  options,
  ref,
  t,
  itemDisplayOpts,
  error,
  errorText,
  disabled,
  onBlur,
  label,
  inputValue,
  displayKey,
  val,
  onChange,
  ...props
}: MyAutoCompleteProps) {
  const [field] = useField(props);
  const [inputvalue, setInputValue] = useState("");

  useEffect(() => {
    if (inputValue) {
      setInputValue(inputValue);
      console.log(val);
    }
  }, [inputValue]);

  return (
    <Autocomplete
      ref={ref}
      // disablePortal
      size="small"
      id={props.name}
      clearOnBlur={true}
      options={options}
      disabled={disabled}
      openText="Open"
      defaultValue={val?.[displayKey] ?? ""}
      inputValue={inputvalue}
      onBlur={onBlur}
      renderOption={(props: any, option: any, state: object) => (
        <span key={props.id}>
          {itemDisplayOpts?.showImage && (
            <span {...props} style={{ marginLeft: 10, fontSize: "16px" }}>
              <span key={option.id} className="c-avatar">
                <img
                  loading="lazy"
                  width="25"
                  style={{ marginRight: 10 }}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
              </span>
              {t?.(option[displayKey]) ?? option[displayKey]}
            </span>
          )}
        </span>
      )}
      getOptionLabel={(option) =>
        option?.code ? `${option.code} - ${option.label}` : ""
      }
      onChange={onChange}
      sx={{ width: "100%" }}
      onInputChange={(event, input, reason) => {
        setInputValue(input);
      }}
      renderInput={({ ...params }) => {
        const { InputLabelProps, InputProps, inputProps, ...rest } = params;
        return (
          <BootstrapInput
            endAdornment={params.InputProps?.endAdornment}
            ref={InputProps?.ref}
            {...params}
          />
        );
      }}
    />
  );
}

export function CountrySelect({
  countries,
  onChange,
  label,
  disabled,
  error,
  errorText,
  ...props
}) {
  const [inputValue, setInputValue] = useState("");
  const theme = useTheme();

  useEffect(() => {
    if (props.value != undefined && props.value != "") {
      setInputValue(props.value.label);
    }
  }, [props.value]);

  return (
    <>
      <Autocomplete
        id="country-select-demo"
        sx={{ width: "100%" }}
        options={countries}
        autoHighlight
        disabled={disabled}
        getOptionLabel={(option: any) => option.label}
        inputValue={inputValue ?? ""}
        defaultValue={props.value ?? null}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={onChange}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 }, fontSize: "12px" }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.label} ({option.code})
          </Box>
        )}
        renderInput={({ ...params }) => {
          const { InputLabelProps, InputProps, inputProps, ...rest } = params;
          return (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  alignItems: "flex-start",
                  p: 0,
                  gap: "4px",
                }}
              >
                {label && (
                  <InputLabel
                    htmlFor={"country-select-demo"}
                    sx={{
                      fontSize: 12,
                      fontWeight: 700,
                      color: "#657482",
                    }}
                  >
                    {label}
                  </InputLabel>
                )}
                <BootstrapInput
                  ref={InputProps?.ref}
                  {...params}
                  sx={{
                    "& .MuiInputBase-input": {
                      ...(!!error && {
                        backgroundColor: theme.palette.error.light,
                        borderColor: theme.palette.error.dark,
                        color: theme.palette.error.dark,
                        "&::placeholder": {
                          opacity: 1,
                          color: theme.palette.error.dark,
                        },
                        "&:focus": {
                          borderColor: theme.palette.error.dark,
                          boxShadow: `0 0 0 0.01rem ${theme.palette.error.dark}`,
                        },
                      }),
                    },
                  }}
                  // endAdornment={<DownArrow />}
                  // endAdornment={"hello"}
                />
                {<MyErrorHelperText error={error} helperText={errorText} />}
              </Box>
            </>
          );
        }}
      />
    </>
  );
}

interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}
