import { Divider } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { TagLabel } from "./AlertRemoveTag";

interface SelfTrainingTagProps {
  tagName: string | null; // Adjust type based on your actual `tagName` type
  enableSelfTraining: boolean;
  setTagName: Dispatch<SetStateAction<string>>;
  removeTagName: string | null;
  setRemoveTagName: Dispatch<SetStateAction<string>>;
}

const SelfTrainingTag: React.FC<SelfTrainingTagProps> = ({
  tagName,
  enableSelfTraining,
  setTagName,
  removeTagName,
  setRemoveTagName,
}) => {
  if (!tagName || !enableSelfTraining) {
    return null;
  }

  return (
    <>
      <Divider
        orientation="vertical"
        sx={{
          height: "20px",
          width: "20px",
          color: "action.active",
          transform: "rotate(270deg)",
        }}
      />
      <TagLabel
        tagName={tagName}
        setTagName={setTagName}
        removeTagName={removeTagName}
        setRemoveTagName={setRemoveTagName}
      />
    </>
  );
};

export default SelfTrainingTag;
