import { Box } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Loader from "src/components/loading/loader";
import useLocales from "src/hooks/useLocales";
import ImageMapper, {
  AreaEvent,
} from "src/pages/formvalidation/Component/ImageMapper/ImageMapper";
import { PageDataViewProps } from "../../types/types";
import ReviewPageSkeleton from "../reviewPageSkeleton";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PageDataView = forwardRef(
  (
    {
      toolbarprops,
      imageLoading,
      editOptionsProps,
      alreadyLabelledAreas,
      height,
      width,
      MAP,
      callback,
      setCallback,
      ...props
    }: PageDataViewProps,
    ref
  ) => {
    const { gatherEvents, addAreaToCombine } = editOptionsProps;
    const { stayMultiHighlighted, editMode } = toolbarprops;
    const [type, setType] = useState("");
    const [image, setImage] = useState("");
    const [pdf, setPdf] = useState<any>();
    const [imageUri, setImageUri] = useState("");
    useImperativeHandle(ref, () => ({
      handleRotate(
        setDimensions: (h: number, w: number, angle: number) => void
      ) {
        const img = new Image();
        img.src = image;
        img.onload = () => {
          rotateImage(img, 90, (rotatedImage, imgH, imgW) => {
            let widthScalefactor = width / imgW;
            let scaledHeight = imgH * widthScalefactor;
            let scaledWidth = imgW * widthScalefactor;
            setImage(rotatedImage);
            console.log("scaled", scaledHeight, scaledWidth);
            setDimensions(scaledHeight, scaledWidth, 90);
          });
        };
      },
    }));
    useEffect(() => {
      setType(props.invoiceType);
      if (props.base64String) {
        if (props.invoiceType?.toLowerCase() == "application/pdf") {
          const byteCharacters = atob(props.base64String ?? "");
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          let pdf_ = new Blob([byteArray], { type: "application/pdf" });
          setPdf(pdf_);
        } else {
          const imageUri_ = `data:image/${props.data?.type};base64,${props.base64String}`;
          setImageUri(imageUri_);
        }
      }
    }, [props.invoiceType, props.data, props.base64String]);

    const onDocumentLoadSuccess = () => {
      const importPDFCanvas: HTMLCanvasElement | any = document.querySelector(
        ".import-pdf-page canvas"
      );
      const pdfAsImageSrc = importPDFCanvas?.toDataURL();

      setImage(pdfAsImageSrc);
      if (callback) {
        setTimeout(() => callback(), 1000);
        setCallback?.(null);
      }
    };

    const clicked = (area: any, ind: number, evt) => {
      props.onClick(area, ind, evt);
    };

    const onMouseEnter = (area: any) => {
      props.onMouseEnter(area);
    };

    const onMouseLeave = (area: any) => {
      props.onMouseLeave(area);
    };

    props.values.map = MAP;
    const { translate } = useLocales();

    const rotateImage = (
      image: HTMLImageElement,
      angle: number,
      callback: (
        rotatedImage: string,
        scaledHeight: number,
        scaledWidth: number
      ) => void
    ) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        throw new Error("Canvas context could not be created");
      }

      const radians = (angle * Math.PI) / 180;
      const sin = Math.abs(Math.sin(radians));
      const cos = Math.abs(Math.cos(radians));

      const width = image.width;
      const height = image.height;

      // Calculate the dimensions of the canvas to fit the rotated image
      const cvsWidth = width * cos + height * sin;
      const cvsHeight = width * sin + height * cos;

      // Set canvas dimensions
      canvas.width = cvsWidth;
      canvas.height = cvsHeight;

      // Translate canvas context to the center
      ctx.translate(cvsWidth / 2, cvsHeight / 2);

      // Rotate the canvas
      ctx.rotate(radians);

      // Draw the image centered at the canvas
      ctx.drawImage(image, -width / 2, -height / 2);

      // Return the rotated image as a Data URL
      callback(canvas.toDataURL("image/png"), canvas.height, canvas.width);
    };

    return (
      <Box sx={{}}>
        {type?.toLowerCase() == "application/pdf" ? (
          <>
            {!image ? (
              imageLoading ? (
                <Box
                  sx={{
                    width: width,
                    height: "65vh",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Loader />
                </Box>
              ) : (
                <Document
                  file={pdf}
                  error={translate("Error loading PDF")}
                  noData={translate("No PDF file specified")}
                  loading={
                    <Box
                      sx={{
                        width: width,
                        height: "65vh",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <Loader />
                    </Box>
                  }
                >
                  <Box sx={{ display: "none" }}>
                    <Page
                      className="import-pdf-page"
                      onRenderSuccess={onDocumentLoadSuccess}
                      pageNumber={1}
                      loading={
                        <Box
                          sx={{
                            width: width,
                            height: "65vh",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Loader />
                        </Box>
                      }
                    />
                  </Box>
                </Document>
              )
            ) : image && props.values?.map ? (
              <>
                <ImageMapper
                  src={image}
                  map={MAP}
                  height={height}
                  width={width}
                  onClick={(area: any, ind: number, evt: AreaEvent) => {
                    if (editMode) {
                      if (area?.key != "") {
                        alreadyLabelledAreas[ind] = area;
                      }
                      addAreaToCombine(area);
                      gatherEvents(evt);
                    } else {
                      clicked(area, ind, evt);
                    }
                  }}
                  onMouseEnter={(area) => onMouseEnter(area)}
                  onMouseLeave={(area) => onMouseLeave(area)}
                  stayHighlighted={false}
                  stayMultiHighlighted={stayMultiHighlighted}
                  strokeColor={stayMultiHighlighted ? "grey" : undefined}
                />
              </>
            ) : (
              <ReviewPageSkeleton />
            )}
          </>
        ) : imageUri && props.values?.map ? (
          <ImageMapper
            src={imageUri}
            map={props.values.map}
            height={height}
            width={width}
            onClick={(area: any, ind: number, evt: AreaEvent) => {
              if (editMode) {
                if (area?.key != "") {
                  alreadyLabelledAreas[ind] = area;
                }
                addAreaToCombine(area);
                gatherEvents(evt);
              } else {
                clicked(area, ind, evt);
              }
            }}
            onMouseEnter={(area) => onMouseEnter(area)}
            onMouseLeave={(area) => onMouseLeave(area)}
            stayHighlighted={false}
            stayMultiHighlighted={stayMultiHighlighted}
            strokeColor={stayMultiHighlighted ? "grey" : undefined}
          />
        ) : (
          <Loader />
        )}
      </Box>
    );
  }
);

export default PageDataView;
