export function createLabelsBoundingBoxes(
  l: any,
  index: number,
  height_: number,
  width_: number,
  ocrHeight: number,
  ocrWidth: number,
  rotation: number
) {
  let bp = l.boundingPolygon;

  const joinBoundingBoxes = [
    bp?.lowerLeft?.x ?? 0,
    bp?.lowerLeft?.y ?? 0,
    bp?.lowerRight?.x ?? 0,
    bp?.lowerRight?.y ?? 0,
    bp?.upperLeft?.x ?? 0,
    bp?.upperLeft?.y ?? 0,
    bp?.upperRight?.x ?? 0,
    bp?.upperRight?.y ?? 0,
  ];
  let height = [90, 270].includes(rotation) ? width_ : height_;
  let width = [90, 270].includes(rotation) ? height_ : width_;
  const scale_ = ocrWidth / ocrHeight;

  const adjustedBounding = joinBoundingBoxes.map((element, index) =>
    index % 2 ? (element * height) / ocrHeight : (element * width) / ocrWidth
  );
  const adjustedPolygon: [number, number][] = [
    [adjustedBounding[0], adjustedBounding[1]], // lowerLeft
    [adjustedBounding[2], adjustedBounding[3]], // lowerRight
    [adjustedBounding[4], adjustedBounding[5]], // upperLeft
    [adjustedBounding[6], adjustedBounding[7]], // upperRight
  ];
  // Calculate the center of the image (for rotation)
  const center: [number, number] =
    rotation === 90
      ? [width / scale_ / 2, width / scale_ / 2]
      : rotation === 270
      ? [(height * scale_) / 2, (height * scale_) / 2]
      : [width / 2, height / 2];

  // Set the rotation angle (e.g., 45 degrees)
  // You can change this value
  // Rotate the polygon
  const rotatedPolygon = rotatePolygon(adjustedPolygon, rotation, center);

  l.coords = adjustedBounding;
  const box = {
    id: "LABEL-" + String(index),
    title: l.value?.replace("\n", " "),
    shape: "poly",
    name: l.name,
    coords: rotatedPolygon?.flat(),
    strokeColor: l.strokeColor,
    preFillColor: l.preFillColor,
    fillColor: l.fillColor,
    key: "",
    boundingPolygon: l.boundingPolygon,
    category: l.name,
    pageNumber: l.pageNumber,
  };
  return box;
}

export function createOcrBoundingBoxes(
  line: any,
  index: number,
  height_: number,
  width_: number,
  ocrHeight: number,
  ocrWidth: number,
  rotation: number
) {
  let bp = line.boundingPolygon;

  const joinBoundingBoxes = [
    bp.lowerLeft?.x ?? 0,
    bp.lowerLeft?.y ?? 0,
    bp.lowerRight?.x ?? 0,
    bp.lowerRight?.y ?? 0,
    bp.upperLeft?.x ?? 0,
    bp.upperLeft?.y ?? 0,
    bp.upperRight?.x ?? 0,
    bp.upperRight?.y ?? 0,
  ];
  let height = [90, 270].includes(rotation) ? width_ : height_;
  let width = [90, 270].includes(rotation) ? height_ : width_;
  const adjustedBounding = joinBoundingBoxes.map((element, index) =>
    index % 2 ? (element * height) / ocrHeight : (element * width) / ocrWidth
  );

  const scale_ = ocrWidth / ocrHeight;
  const adjustedPolygon: [number, number][] = [
    [adjustedBounding[0], adjustedBounding[1]], // lowerLeft
    [adjustedBounding[2], adjustedBounding[3]], // lowerRight
    [adjustedBounding[4], adjustedBounding[5]], // upperLeft
    [adjustedBounding[6], adjustedBounding[7]], // upperRight
  ];

  // Calculate the center of the image (for rotation)
  const center: [number, number] =
    rotation === 90
      ? [width / scale_ / 2, width / scale_ / 2]
      : rotation === 270
      ? [(height * scale_) / 2, (height * scale_) / 2]
      : [width / 2, height / 2];
  // Set the rotation angle (e.g., 45 degrees)
  // You can change this value

  // Rotate the polygon
  const rotatedPolygon = rotatePolygon(adjustedPolygon, rotation, center);

  const box = {
    id: "OCR-" + String(index),
    title: line.content,
    shape: "poly",
    name: "un_labelled",
    coords: rotatedPolygon?.flat(),
    strokeColor: "", //  "#B6B6B680",
    preFillColor: "", //  "#F7F4A420",
    fillColor: "", // "#F7F4A420",
    key: "",
    boundingPolygon: line.boundingPolygon,
    category: "un_labelled",
    pageNumber: line.pageNumber,
  };
  return box;
}

function rotatePolygon(
  polygon: [number, number][], // Array of [x, y] coordinates
  angle: number, // Rotation angle in degrees
  center: [number, number] // Rotation center [cx, cy]
): [number, number][] {
  const angleRad = (angle * Math.PI) / 180; // Convert angle to radians
  const [cx, cy] = center;

  return polygon.map(([x, y]) => {
    // Translate point to origin
    const dx = x - cx;
    const dy = y - cy;
    let cos = Math.cos(angleRad);
    let sin = Math.sin(angleRad);
    // if (Math.abs(cos) < 1e-10) cos = 0;
    // if (Math.abs(sin) < 1e-10) sin = 0;
    // Apply rotation
    const xRotated = dx * cos - dy * sin;
    const yRotated = dx * sin + dy * cos;
    return [xRotated + cx, yRotated + cy];
  });
}
