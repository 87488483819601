import { Box, Typography } from "@mui/material";
import React from "react";
import useLocales from "src/hooks/useLocales";

interface ErrorScreenProps {
  message: string;
  icon: JSX.Element;
}

const ErrorScreen: React.FC<ErrorScreenProps> = ({ icon, message }) => {
  const { translate } = useLocales();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box>{icon}</Box>
      <Typography variant="body1" sx={{ mt: 1 }}>
        {translate(message)}
      </Typography>
    </Box>
  );
};

export default ErrorScreen;
