const global = {
  complete: `Completar`,
  address: `Dirección`,
  email: `Correo electrónico`,
  accepted: `Aceptado`,
  not_accepted: `No aceptado`,
  individual_information: `Información individual`,
  full_name: `Nombre completo`,
  nationality: `Nacionalidad`,
  document: `Documento`,
  terms_and_conditions: `Términos y condiciones`,
  updated_by: `Actualizado por`,
  updated_on: `Actualizado el`,
  last_updated_by: `Última actualización realizada por {{updatedBy}} el {{lastUpdated}}`,
  last_updated_on: `Última actualización: {{lastUpdated}}`,
  connecting: `Conectando`,
  today: `Hoy`,
  load_more: "Cargar más",
  download_csv: "Descargar CSV",
  end_of_result: `Fin del resultado`,
  recipient: `Beneficiario`,
  note: `Nota`,
  total_balance: "Saldo total",
  file_name: `Nombre del archivo`,
  account: `Cuenta`,
  company_id: `ID de la empresa`,
  view_history: `Ver historial`,
  view_change_logs: `Ver registros de cambios`,
  include_onboarded_users: `Incluir usuarios incorporados`,
  show_onboarded_customers: `Mostrar clientes incorporados`,
  show_onboarded_suppliers: `Mostrar proveedores incorporados`,
  disconnect: `Desconectar`,
  configure: "Configurar",
  documents: `Documentos`,
  in_process: `En proceso`,
  connect: `Conectar`,
  continue: `Continuar`,
};
export default global;
