const exportConfiguration = {
  view_export_template_logs: `Ver registros de exportación`,
  export_template_logs: `Registros de plantillas de exportación`,
  exported_at: `Exportado en`,
  no_logs_found: `No se encontraron registros`,
  delete_template: `Eliminar plantilla`,
  export_template: `exportar plantilla`,
  Export_template: `Exportar plantilla`,
  delete_template_warning: `Las plantillas eliminadas no se pueden recuperar`,
  template_deleted_successfully: `Plantillas eliminadas con éxito`,
  add_export_template: `Agregar plantilla de exportación`,
  csv_exported: `CSV exportado`,
};

export default exportConfiguration;
