import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { Dispatch, useContext, useState } from "react";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import { ReactComponent as DeleteColumnIcon } from "src/assets/icon/table/removeColumn.svg";
import MyTablePagination from "src/components/pagination/TablePagination";
import useLocales from "src/hooks/useLocales";
import { ReviewContext } from "src/pages/formvalidation/context/ReviewContext";
import { FormState } from "src/pages/formvalidation/hooks/useItemsTable";
import { store } from "src/redux/store";
import Headings from "./Header";
import NewRowPopoverButton from "./NewRowPopover";
import TableValues from "./TableRow";

export default function ItemsTable(props: {
  formState: FormState;
  dispatch: Dispatch<any>;
  open: boolean;
  handleItemsClose: any;
  itemsListKey: string;
  setFieldValue: any;
  getFieldValue: any;
}) {
  const {
    open,
    handleItemsClose,
    itemsListKey,
    formState,
    dispatch,
    getFieldValue,
  } = props;
  const reviewCon = useContext(ReviewContext);
  const { setInitialState } = reviewCon.initial;
  const [enableDeleteColumn, setEnableDeleteColumn] = useState(false);
  const [enableDeleteTable, setEnableDeleteTable] = useState(false);
  const reviewContext = useContext(ReviewContext);
  const { reviewResponse } = reviewContext;
  const { dispatch: reviewDispatch } = reviewResponse;

  const newRow = (rowNumber) => {
    // dispatch({ type: "INSERT_ROW_AT_POSITION", payload: { index: rowNumber } });
    if (rowNumber) {
      dispatch({
        type: "NEW_ROW_AT_INDEX",
        payload: rowNumber - 1,
      });
    } else {
      dispatch({ type: "NEW_ROW" });
    }
  };

  const handleDeleteColumn = (name: string) => {
    dispatch({
      type: "DELETE_COLUMN",
      payload: {
        enableDeleteTable,
        name,
      },
    });
  };

  const handleClose = () => {
    handleItemsClose();
    dispatch({ type: "RESET_STATE" });
  };

  const checkDefaultHeaders = (header: string) => {
    if (formState?.field?.tableDefaultProperties?.includes(header)) {
      return true;
    }
    return false;
  };

  const deleteRow = (index) => {
    dispatch({ type: "DELETE_ROW", payload: index });
    let payload: any = {
      name: formState?.field?.name,
      value: null,
      pageNumber: null,
      confidence: null,
      isRemoved: false,
      isNew: false,
      itemsFields: [
        {
          index: formState?.field?.itemsFields?.[index]?.index,
          item: [],
          isRemoved: true,
          isNew: false,
        },
      ],
    };
    formState?.field?.itemsFields?.[index]?.item?.forEach((item) => {
      payload.itemsFields[0].item.push({
        name: item.name,
        value: item.value,
        pageNumber: item.pageNumber,
        boundingPolygon: item.boundingPolygon,
        confidence: item.confidence,
        isRemoved: true,
        isNew: false,
      });
    });
    dispatch({
      type: "REMOVE_FIELD",
      payload: [payload],
    });
  };

  const getTranslatedFields = (title) => {
    let result = translate(`review_screen.review_fields.${title}`);
    if (result.indexOf("review_screen") === -1) {
      return result;
    }
    return title;
  };
  const { translate } = useLocales();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const onChangePage = (event: any, page: number) => {
    dispatch({ type: "CHANGE_PAGE", payload: page });
  };
  const onChangeRowsPerPage = (event: SelectChangeEvent<any>, value: any) => {
    setRowsPerPage(event.target.value as number);
  };
  const {
    org: { enableAsyncReviewPage },
  } = store.getState()?.organization;
  const theme = useTheme();

  return (
    <>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          maxWidth="xl"
          fullWidth={true}
          scroll="body"
          aria-describedby="alert-dialog-description"
        >
          <form
            onSubmit={(values) => {
              props.setFieldValue(itemsListKey, formState.field);
              reviewDispatch({
                type: "ADD_FIELD",
                payload: formState.recordField,
              });
              setInitialState((initState) => {
                let fields = initState.fields;
                let index = itemsListKey?.split(".")?.[1];
                fields[index] = values;
                return {
                  ...initState,
                  fields: fields,
                };
              });
              handleClose();
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  pt: 3,
                  pr: 3.5,
                  ml: "auto",
                }}
              >
                <IconButton
                  sx={{ width: 10, height: 8 }}
                  onClick={handleItemsClose}
                >
                  <CloseModalButton />
                </IconButton>
              </Box>
            </Box>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "bottom",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5">
                  {getTranslatedFields(formState.field.name)}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "end",
                    pb: 1,
                  }}
                >
                  <Tooltip title={translate("Remove column")} arrow>
                    <span>
                      <IconButton
                        disabled={
                          formState?.headings.length === 0 ||
                          enableAsyncReviewPage
                        }
                        size="small"
                        sx={{
                          marginLeft: 1,
                          borderRadius: "8px",
                          height: "27px",
                          width: "27px",
                          color: "grey.400",
                          bgcolor: "background.paper",
                          border: "1px solid " + theme.palette.grey[400],
                          cursor: "pointer",
                          "&:hover": { color: "black" },
                        }}
                        onClick={(e) => {
                          setEnableDeleteColumn(!enableDeleteColumn);
                        }}
                      >
                        <DeleteColumnIcon
                          height={18}
                          width={18}
                          color={
                            formState?.headings.length === 0 ||
                            enableAsyncReviewPage
                              ? theme.palette.grey[200]
                              : theme.palette.primary.main
                          }
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <NewRowPopoverButton
                    newRow={newRow}
                    disabled={formState?.headings.length === 0}
                    total={formState?.rows.length}
                  />
                </Box>
              </Box>
              <TableContainer
                sx={{
                  maxHeight: "50vh",
                }}
              >
                <Table
                  // stickyHeader
                  size="small"
                  aria-label="table with items"
                  sx={{
                    px: 2,
                    maxHeight: "65vh",
                  }}
                >
                  <TableHead>
                    <Headings
                      headers={formState?.headings}
                      handleDeleteColumn={handleDeleteColumn}
                      enableDeleteColumn={enableDeleteColumn}
                      setEnableDeleteTable={setEnableDeleteTable}
                      enableDeleteTable={enableDeleteTable}
                      checkDefaultHeaders={checkDefaultHeaders}
                    />
                  </TableHead>
                  <TableBody>
                    {formState?.rows
                      ?.slice(
                        formState?.page * rowsPerPage,
                        formState?.page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row, i) => (
                        <TableValues
                          key={"row-" + i + "-" + row?.line}
                          row={row}
                          header={formState?.headings}
                          i={i}
                          page={formState?.page * rowsPerPage}
                          rows={formState?.rows}
                          dispatch={dispatch}
                          deleteRow={deleteRow}
                          length={formState?.rows.length}
                          enableDeleteColumn={enableDeleteColumn}
                          getFieldValue={getFieldValue}
                          getTranslatedFields={getTranslatedFields}
                        />
                      ))}
                    {formState?.rows?.slice(
                      formState?.page * rowsPerPage,
                      formState?.page * rowsPerPage + rowsPerPage
                    ).length > 0 && (
                      <TableRow sx={{}}>
                        <TableCell
                          sx={{
                            width: "100%",
                          }}
                        ></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <MyTablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  count={formState?.rows.length}
                  rowsPerPage={rowsPerPage}
                  disableSelectRowsPerPage
                  labelRowsPerPage={translate("finance_screen.rows_per_page")}
                  page={formState?.page}
                  onPageChange={(event, page) => {
                    onChangePage(event, page);
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <Button
                    size="medium"
                    onClick={handleClose}
                    variant="outlined"
                  >
                    {translate("Cancel")}
                  </Button>
                  <MemoizedButton>{translate("Save")}</MemoizedButton>
                </Box>
              </Box>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
}

const MemoizedButton = React.memo(({ children }) => {
  return (
    <Button size="medium" type="submit" variant="contained">
      {children}
    </Button>
  );
});
