import { Box, SvgIconProps, useTheme } from "@mui/material";

export const OpenIcon = ({
  color = "action",
  height = 24,
  width = 24,
  disableDimension = false,
}: SvgIconProps & {
  disableDimension?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...(disableDimension
          ? {}
          : {
              display: "flex",
              height: height,
              width: width,
            }),
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 5.99864L4 5.99864C2.89543 5.99864 2 6.89407 2 7.99864L2 19.9986C2 21.1032 2.89543 21.9986 4 21.9986H16C17.1046 21.9986 18 21.1032 18 19.9986L18 16.1986M18.8716 5.125L8.70758 15.289M12.6178 5.12603L18.8726 5.12603V11.3808"
          stroke={theme.palette?.[color]?.active}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};
