import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Typography } from "@mui/material";
import { formatRelative } from "date-fns";
import { es } from "date-fns/locale";
import { WorkflowHistoryDetailsEntity } from "src/@types/workflows/workflowHistory";
import FilterIcon from "src/assets/icon/filter";
import useLocales from "src/hooks/useLocales";
import WorkflowDetailItem from "./WorkflowDetailItem";

interface WorkflowHistoryDetailsProps {
  workflowHistoryDetails: WorkflowHistoryDetailsEntity[] | null | undefined;
  isLoading: boolean;
}

const WorkflowHistoryDetails: React.FC<WorkflowHistoryDetailsProps> = (
  props
) => {
  const { workflowHistoryDetails, isLoading } = props;
  const { currentLang } = useLocales();
  return (
    <Timeline position="right">
      {workflowHistoryDetails?.map((workflowDetail, index) => (
        <TimelineItem>
          <TimelineOppositeContent
            color="text.secondary"
            sx={{
              flex: 0,
              minWidth: 100,
            }}
          >
            <Typography variant="body2" textAlign="right">
              {formatRelative(new Date(workflowDetail.updatedOn), new Date(), {
                locale: currentLang.value === "es" ? es : undefined,
              })}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              variant="outlined"
              sx={{
                height: 22,
                width: 22,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FilterIcon height={15} width={15} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <WorkflowDetailItem {...{ workflowDetail }} />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default WorkflowHistoryDetails;
