import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import Xarrow, { Xwrapper } from "react-xarrows";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import DeleteIcon from "src/assets/icon/delete";
import InfoIcon from "src/assets/icon/info";
import { MailIcon } from "src/assets/icon/Mail";
import PlusIcon from "src/assets/icon/plus";
import { GetAsync, PostAsync, PutAsync } from "src/common/httpRequests";
import MyErrorHelperText from "src/components/denseForm/ErrorHelperText";
import MyHelperText from "src/components/denseForm/HelperText";
import { ReviewAutoComplete } from "src/components/form/AutocompleteField";
import { MyDatePickerFormField } from "src/components/form/DateField";
import MyInputLabel from "src/components/form/InputLabel";
import { MySelectFormField } from "src/components/form/SelectField";
import MySwitchField from "src/components/form/switch";
import {
  MySelectField,
  MyTextField,
  MyTextFormField,
} from "src/components/FormFields";
import { API_GATEWAY } from "src/constants/settings";
import useLocales from "src/hooks/useLocales";
import { MixpanelFunction } from "src/mixpanel/mixpanelFunction";
import { fDate } from "src/utils/formatTime";
import * as Yup from "yup";
import { Action, TCondition, TworkflowParameter } from "./type";

type WorkflowProps = {
  open: boolean;
  handleClose: VoidFunction;
  getWorkFlowsApi: () => Promise<void>;
  id: string;
  action: Action;
};
export default function WorkflowModal(props: WorkflowProps) {
  const { open, handleClose, getWorkFlowsApi, id, action } = props;
  const { translate } = useLocales();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const ref: any = useRef();

  const initValues = {
    name: "",
    conditions: [
      {
        parameter: "",
        operation: "",
        inputValue: "",
      },
    ],
    approvers: [],
    isReviewDocument: false,
    approvalNotRequired: false,
  };

  const [parameterList, setParameterList] = useState<any>([]);
  const [approversList, setApproversList] = useState<any>([]);
  const [initialValue, setInitialValue] = useState<any>({ ...initValues });
  const [parameterOptionMap, setParameterOptionMap] = useState<any>({});
  const [loading, setLoading] = useState(true);

  const getAutoCompleteTranslatedOption = (item: string) => {
    let result = "";
    if (item.split(".").length == 2) {
      let result1 = translate(
        `review_screen.review_fields.${item.split(".")?.[0]}`
      );
      let result2 = translate(
        `review_screen.review_fields.${item.split(".")?.[1]}`
      );

      result =
        (result1.indexOf("review_screen") == -1
          ? result1
          : item.split(".")?.[0]) +
        " " +
        (result2.indexOf("review_screen") == -1
          ? result2
          : item.split(".")?.[1]);
    } else {
      result = translate(`review_screen.review_fields.${item}`);
    }
    if (result.indexOf("review_screen") == -1) {
      return { value: item, label: result };
    }
    return { value: item, label: item };
  };

  const t = (key: string) => translate(key);

  const getworkflowById = (id: string) => {
    GetAsync(`${API_GATEWAY}/api/workflowservice/workflows/${id}`)
      .then((res) => {
        if (res?.data) {
          setInitialValue({
            name: action === "edit" ? res.data?.name : "",
            conditions:
              res.data.conditions?.map((condition: TCondition) => {
                return {
                  parameter: condition.parameter,
                  operation: condition.operation,
                  inputValue: condition.inputValue ?? condition.formattedValue,
                };
              }) ?? [],
            approvers: res.data.approvers,
            isReviewDocument: res.data.isReviewDocument,
            approvalNotRequired: res?.data?.approvalNotRequired,
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(JSON.stringify(err), { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateParameterOptionMap = (
    listOfParameters: TworkflowParameter[]
  ) => {
    listOfParameters.forEach((parameter: TworkflowParameter) => {
      const { dataType, expectedValues, fieldType, key, name, operations } =
        parameter;
      parameterOptionMap[parameter.key] = {
        expectedValues: expectedValues,
        operations: operations,
        dataType: dataType,
        fieldType: fieldType,
      };
    });
    setParameterOptionMap({ ...parameterOptionMap });
  };

  const generateParameterOptions = (listOfParameters: TworkflowParameter[]) => {
    const options = listOfParameters.map((parameter) => ({
      value: parameter.key,
      label: getAutoCompleteTranslatedOption(parameter.key).label,
    }));
    return options;
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(translate("Name is required")),
    conditions: Yup.array().of(
      Yup.object().shape({
        parameter: Yup.string().required(translate("Parameter is required")),
        operation: Yup.string().required(translate("Operation is required")),
        inputValue: Yup.string().test(
          "inputValue",
          translate("Value is required"),
          function (value) {
            if (parameterOptionMap?.[this.parent.parameter]?.fieldType == 1) {
              return true;
            } else if (!value) {
              return false;
            }
            return true;
          }
        ),
      })
    ),
    approvers: Yup.array()
      .of(Yup.string().required(translate("Approver is required")))
      .required(t("Atleast 1 approver is required"))
      .min(1, translate("Atleast 1 approver is required")),
  });

  const getWorkflowConfiguration = () => {
    GetAsync(`${API_GATEWAY}/api/workflowservice/workflows/configuration`)
      .then((res) => {
        if (res?.data) {
          setParameterList(generateParameterOptions(res.data.paramters));
          setApproversList(res.data.approvers);
          generateParameterOptionMap(res.data.paramters);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const mixpanelFunctionOnSubmit = (values) => {
    let parameter: string[] = [];
    let operation: string[] = [];
    let inputValue: any[] = [];
    for (let i = 0; i < values.conditions.length; i++) {
      parameter.push(values.conditions[i].parameter);
      operation.push(values.conditions[i].operation);
      inputValue.push(values.conditions[i].inputValue);
    }
    MixpanelFunction({
      eventName: "Workflow Added",
      workflowEmail: values.approvers[0],
      workflowName: values.name,
      enableReview: values.isReviewDocument,
      parameters: parameter,
      operations: operation,
      inputValues: inputValue,
    });
  };

  const checkDuplicateWorkflow = (values: {
    conditions: TCondition[];
  }): boolean => {
    let conditions = initialValue.conditions;
    let isDuplicate = true;
    let duplicateArray: boolean[] = [];
    for (let i = 0; i < values.conditions.length; i++) {
      const condition = values.conditions[i];
      if (
        condition.parameter === conditions[i].parameter &&
        condition.operation === conditions[i].operation &&
        condition.inputValue === conditions[i].inputValue
      ) {
        duplicateArray.push(true);
      } else {
        duplicateArray.push(false);
      }
    }
    if (duplicateArray.includes(false)) {
      isDuplicate = false;
    }
    return isDuplicate;
  };
  // const [value, setValue] = useState(false);

  useEffect(() => {
    if (id) getworkflowById(id);
  }, [id]);

  useEffect(() => {
    getWorkflowConfiguration();
  }, []);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      scroll="body"
      onClose={() => {
        setInitialValue({ ...initValues });
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle id="modal-modal-title">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" sx={{ textAlign: "left", my: 1 }}>
            {action === "edit"
              ? t("Edit workflow")
              : action === "duplicate"
              ? t("Duplicate workflow")
              : t("New Workflow")}
          </Typography>

          <IconButton
            sx={{
              height: "10px",
              width: "8px",
            }}
            onClick={() => {
              setInitialValue({ ...initValues });
              handleClose();
            }}
          >
            <CloseModalButton />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValue}
          enableReinitialize
          innerRef={ref}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            // if (id) {
            // let check = checkDuplicateWorkflow(values);
            // if (check) {
            //   enqueueSnackbar("Workflow already exists", {
            //     variant: "error",
            //   });
            //   setSubmitting(false);
            //   return;
            // }
            // }
            let approvers = values.approvers?.filter((approver) => {
              return approversList?.includes(approver);
            });
            values.approvers = approvers;
            let resp =
              action === "edit"
                ? PutAsync(
                    `${API_GATEWAY}/api/workflowservice/workflows/${id}/update`,
                    values
                  )
                : PostAsync(
                    `${API_GATEWAY}/api/workflowservice/workflows`,
                    values
                  );
            resp
              .then((res) => {
                if (
                  res?.status === 200 ||
                  res?.status === 201 ||
                  res?.status === 204
                ) {
                  enqueueSnackbar(
                    translate(
                      action === "edit"
                        ? "workflow_updated_successfully"
                        : "workflow_created_successfully"
                    ),
                    {
                      variant: "success",
                    }
                  );
                  mixpanelFunctionOnSubmit(values);
                  setTimeout(() => {
                    getWorkFlowsApi();
                    handleClose();
                    setSubmitting(false);
                  }, 1000);
                  return;
                } else {
                  enqueueSnackbar(translate(res.data.Detail), {
                    variant: "error",
                  });
                  setSubmitting(false);
                  return;
                }
              })
              .catch((err) => {
                enqueueSnackbar(JSON.stringify(err.data.Detail), {
                  variant: "error",
                });
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            setFieldValue,
            isSubmitting,
            errors,
            setFieldError,
            touched,
          }) => (
            <Form>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                  pb: 2,
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <MyTextField
                    name="name"
                    label={t("Workflow name")}
                    placeholder={t("Put a name for your workflow")}
                    disabled={action === "edit"}
                  />
                </Box>
                <Box sx={{ width: "50%", display: "flex" }}>
                  <MySwitchField
                    name="approvalNotRequired"
                    checked={values?.approvalNotRequired}
                    value={initialValue?.approvalNotRequired}
                    onChange={(event, checked) => {
                      setFieldValue("approvalNotRequired", checked);
                    }}
                    disabled={action === "edit"}
                    label={
                      <b style={{ color: "#657482" }}>
                        {t("disable_workflow_approval")}
                      </b>
                    }
                  />
                  <Tooltip
                    title={
                      <>
                        {translate(`workflow_info_0`)}
                        <ul>
                          <li>{translate(`workflow_info_1`)}</li>
                          <li>{translate(`workflow_info_2`)}</li>
                        </ul>
                      </>
                    }
                    placement="top"
                  >
                    <Box>
                      <InfoIcon
                        customColor={theme.palette.action.active}
                        height={20}
                        width={20}
                      />
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
              <Box
                sx={{
                  bgcolor: "background.neutral",
                  borderRadius: 1,
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    ...(values?.conditions?.length > 1
                      ? {
                          pl: 2,
                          ml: 4,
                        }
                      : {}),
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    mr: 0,
                    justifyContent: "space-between",
                  }}
                >
                  <Xwrapper>
                    {values?.conditions?.length > 1 && (
                      <Xarrow
                        start={"comp1"}
                        end={"comp" + (values?.conditions?.length ?? 0)}
                        startAnchor={{
                          position: "left",
                          offset: { x: -12, y: -12 },
                        }}
                        endAnchor={{
                          position: "left",
                          offset: { x: -18, y: 12 },
                        }}
                        headShape={"circle"}
                        tailShape={"circle"}
                        strokeWidth={1}
                        gridBreak="50%"
                        path="grid"
                        showTail={true}
                        SVGcanvasProps={{
                          width: "100px",
                        }}
                        // _debug
                        _cpx1Offset={-24}
                        _cpx2Offset={-24}
                        _cpy1Offset={0}
                        _cpy2Offset={0}
                        color={theme.palette.secondary.main}
                        arrowBodyProps={{
                          // curve the path at the edge
                          offset: 0.5,
                          startOffset: 0,
                        }}
                        labels={{
                          middle: (
                            <Box
                              sx={{
                                zIndex: 10,
                                borderRadius: "30px",
                                border: `1px solid ${theme.palette.secondary.main}`,
                                backgroundColor:
                                  theme.palette.background.neutral,
                                px: 1,
                              }}
                            >
                              <Typography
                                variant="body1"
                                color={"secondary.main"}
                              >
                                {translate(`And`)}
                              </Typography>
                            </Box>
                          ),
                        }}
                      />
                    )}
                    {values.conditions?.map(
                      (item: TCondition, index: number) => {
                        return (
                          <Box
                            id={`comp${index + 1}`}
                            sx={{
                              display: "flex",
                              alignItems: "flex-end",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              width: "90%",
                              gap: 1.5,
                              p: 0,
                            }}
                          >
                            <Grid
                              container
                              rowSpacing={4}
                              spacing={2}
                              sx={{ p: "2px" }}
                            >
                              <Grid item xs={12} sm={4}>
                                {index == 0 && (
                                  <MyInputLabel>
                                    {t("If the variable")}
                                  </MyInputLabel>
                                )}
                                <ReviewAutoComplete
                                  displayKey={"label"}
                                  name={`conditions.${index}.parameter`}
                                  placeholder={t("Variable selected")}
                                  options={parameterList ?? []}
                                  val={values?.conditions?.[index]?.parameter}
                                  error={
                                    errors?.conditions?.[index]?.parameter &&
                                    touched.conditions?.[index]?.parameter
                                      ? errors?.conditions?.[index]?.parameter
                                      : ""
                                  }
                                  menuOptionProps={{
                                    showValueKey: true,
                                    valueDisplayKey: "value",
                                  }}
                                  onChange={(e, value) => {
                                    if (value) {
                                      setFieldValue(`conditions.${index}`, {
                                        parameter: value.value,
                                        operation: "",
                                        inputValue: "",
                                      });
                                      setFieldError(
                                        `conditions.${index}.parameter`,
                                        undefined
                                      );
                                    } else {
                                      setFieldValue(`conditions.${index}`, {
                                        parameter: "",
                                        operation: "",
                                        inputValue: "",
                                      });
                                    }
                                  }}
                                />

                                {errors?.conditions?.[index]?.parameter &&
                                touched.conditions?.[index]?.parameter ? (
                                  <MyErrorHelperText
                                    error={
                                      !!errors?.conditions?.[index]?.parameter
                                    }
                                    helperText={
                                      errors?.conditions?.[index]?.parameter
                                    }
                                  />
                                ) : (
                                  <MyHelperText
                                    helperText={`${translate("Field name")}: ${
                                      values?.conditions?.[index]?.parameter ??
                                      ""
                                    }`}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                {index == 0 && (
                                  <MyInputLabel>{t("Is")}</MyInputLabel>
                                )}
                                <MySelectFormField
                                  name={`conditions.${index}.operation`}
                                  placeholder={t("Condition selected")}
                                  options={
                                    parameterOptionMap?.[
                                      values.conditions?.[index]?.parameter
                                    ]?.operations?.map((op: string) => {
                                      return { label: t(op), value: op };
                                    }) ?? []
                                  }
                                />
                              </Grid>

                              <Grid container spacing={1} item xs={12} sm={4}>
                                <Grid item xs={10}>
                                  {index == 0 && (
                                    <MyInputLabel>
                                      {t("to the value")}
                                    </MyInputLabel>
                                  )}
                                  {parameterOptionMap?.[
                                    values.conditions?.[index]?.parameter
                                  ]?.fieldType == 1 ? (
                                    parameterOptionMap?.[
                                      values.conditions?.[index]?.parameter
                                    ]?.dataType == 2 ? (
                                      <>
                                        <MyDatePickerFormField
                                          value={
                                            values.conditions?.[index]
                                              ?.inputValue
                                          }
                                          onChange={(newValue) => {
                                            setFieldValue(
                                              `conditions.${index}.inputValue`,
                                              fDate(newValue)
                                            );
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <MyTextFormField
                                          name={`conditions.${index}.inputValue`}
                                          placeholder={t("Value")}
                                          type={
                                            parameterOptionMap?.[
                                              values.conditions?.[index]
                                                ?.parameter
                                            ]?.dataType
                                          }
                                        />
                                      </>
                                    )
                                  ) : parameterOptionMap?.[
                                      values.conditions?.[index]?.parameter
                                    ]?.fieldType == 2 ? (
                                    <>
                                      <MySelectFormField
                                        name={`conditions.${index}.inputValue`}
                                        placeholder={t("Value")}
                                        options={
                                          parameterOptionMap?.[
                                            values.conditions?.[index]
                                              ?.parameter
                                          ]?.expectedValues?.map(
                                            (op: string) => ({
                                              label:
                                                values.conditions?.[index]
                                                  ?.parameter ===
                                                "Discrepancies.DetectedDiscrepancy"
                                                  ? t(
                                                      `workflow_discrepancy_${op}`
                                                    )
                                                  : t(op),
                                              value: op,
                                            })
                                          ) ?? []
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <MyTextFormField
                                        id={`node${index + 2}_${2}`}
                                        name={`conditions.${index}.inputValue`}
                                        placeholder={t("Value")}
                                        type={
                                          parameterOptionMap?.[
                                            values.conditions?.[index]
                                              ?.parameter
                                          ]?.dataType
                                        }
                                      />
                                    </>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  sx={{ mt: index === 0 ? 2.5 : -0.3 }}
                                >
                                  <IconButton
                                    size="small"
                                    sx={{
                                      color: "#657482",
                                      bgcolor: "#E4E6EB",
                                      height: "30px",
                                      width: "30px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      let idx = index;
                                      let temp = [...values.conditions];
                                      temp.splice(idx, 1);
                                      setFieldValue("conditions", temp);
                                    }}
                                  >
                                    <DeleteIcon customColor="#657482" />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      }
                    )}
                  </Xwrapper>
                </Box>
                <Button
                  sx={{
                    mt: 1,
                    textAlign: "left",
                    color: theme.palette.primary.main,
                    fontWeight: 700,
                    fontSize: "12px",
                  }}
                  endIcon={<PlusIcon color="primary" height={16} width={16} />}
                  type="button"
                  size="small"
                  variant="text"
                  color="primary"
                  onClick={() => {
                    values.conditions.push({
                      parameter: "",
                      operation: "",
                      inputValue: "",
                    });
                    setFieldValue("conditions", values.conditions);
                  }}
                >
                  {t("Add Condition")}
                </Button>
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={10}>
                  <MySelectField
                    name="approvers"
                    label={t("Approvers")}
                    placeholder={t("Add a new email")}
                    values={values?.approver}
                    startAdornment={
                      <MailIcon
                        height={24}
                        width={24}
                        sx={{
                          position: "absolute",
                          left: "6px",
                          top: "calc(50% - 16px)",
                          pointerEvents: "none",
                          color: errors?.approvers ? "#B72136" : "#657482",
                          zIndex: 10,
                        }}
                      />
                    }
                    options={
                      approversList?.map(
                        (p: { label: string; value: string }) => ({
                          label: p,
                          value: p,
                        })
                      ) ?? []
                    }
                    multiple
                    error={touched.approvers && errors?.approvers}
                    onChange={(e) => {
                      setFieldValue(
                        `approvers`,
                        typeof e.target.value === "string" &&
                          e.target.value != ""
                          ? e.target.value.split(",")
                          : e.target.value
                      );
                      setFieldError(`approvers`, undefined);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <MySelectField
                    name="isReviewDocument"
                    label={t("review_")}
                    fullWidth
                    options={[
                      { value: true, label: t("Yes") },
                      { value: false, label: t("No") },
                    ]}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <Button
                  variant="outlined"
                  size="medium"
                  sx={{
                    marginRight: "10px",
                  }}
                  disabled={isSubmitting}
                  onClick={() => {
                    setInitialValue({ ...initValues });
                    handleClose();
                  }}
                >
                  {translate("cancel")}
                </Button>
                <LoadingButton
                  type="submit"
                  size="medium"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {translate("save")}
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
