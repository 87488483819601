export const businessCentralConfiguration = {
  business_central_configurations: `Configuraciones de Business Central`,
  bc_integration_unsupported: `La integración de Business Central no es compatible`,
  bc_postprocessed_json_required: `El JSON postprocesado de Business Central es obligatorio`,
  bc_docCls_actCls_required: `La clase de documento y la clase de cuenta de Business Central son obligatorias`,
  bc_companyname_required: "El nombre de la empresa es obligatorio.",
  bc_customername_required: "El nombre del cliente es obligatorio.",
  bc_items_required: "Los artículos son obligatorios.",
  bc_item_qty_required: "La cantidad de artículos es obligatoria.",
};
