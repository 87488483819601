export const holdedConfiguration = {
  holded_configurations: "Holded configurations",
  "Last sync date": `Last sync date`,
  "Sync Now": `Sync Now`,
  "Entities being synced": `Entities being synced`,
  "Synced successfully": `Synced successfully`,
  "Updated successfully": `Updated successfully`,
  "Failed to update": `Failed to update`,
  "Sync request sent": "Sync request sent",
  "There were some issues encountered during the synchronization process":
    "There were some issues encountered during the syncing process.",
  "Add single / multiple API keys by pressing 'Enter' after each one.":
    "Add single / multiple API keys by pressing 'Enter' after each one.",
  "Sync start date": "Sync start date",
  "API keys": "API keys",
  connector_not_connected: "Connector not connected",
  connector_being_synced: "Connector being synced",
  connector_being_sycned: "Connector being synced",
  holdedV2_not_enabled: "You are not connected with Holded v2",
  authentication_failed: `Your provided Holded api key(s) failed to authenticate`,
  connector_not_defined: "The key already exists",
  something_went_wrong: "Something went wrong",
  holded_companykey_required: `Holded CompanyKey is required`,
  holded_vendorname_required: `Holded VendorName is required`,
  holded_invoicedate_required: `Holded InvoiceDate is required`,
  holded_invoiceid_required: `Holded InvoiceId is required`,
  holded_items_required: `Holded Items are required`,
  holded_integration_unsupported: `Holded integration unsupported`,
  holded_postprocessed_json_required: `Holded postprocessed json is requiured`,
  holded_docCls_actCls_required: `Holded document class and account class is required`,
  holded_item_qty_required: `Holded item quantity is requiured`,
  holded_item_unitprice_required: `Holded item unit price is required`,
};
