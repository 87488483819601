const mappings = {
  mapping_configuration: `Configuración de mapeo`,
  view_mapping_details: "Ver detalles del mapeo",
  mapping_details: "Detalles del mapeo",
  mappings: `Cartografías`,
  mapping_not_created: `Mapeo no creado`,
  mapping_download_request_inprocess: `Solicitud de descarga en proceso`,
};

export default mappings;
