import { useContext, useEffect, useState } from "react";
import { GetAsync } from "src/common/httpRequests";
import { API_GATEWAY } from "src/constants/settings";
import { ReviewContext } from "../context/ReviewContext";
type TSearchFields = {
  field: string;
  parentField: string;
  parentFieldValue: string;
  search: string;
  page: number;
  record: number;
};

const useAsyncExpectedValues = ({
  editValue,
  getFieldValue,
  row,
}: {
  editValue;
  getFieldValue;
  row?;
}) => {
  const reviewContext = useContext(ReviewContext);
  const { expectedValues } = reviewContext;
  const { documentFieldsExpectedValueConfigs } = expectedValues;
  const [expectedValuesOption, setExpectedValuesOption] = useState<any[]>([]);
  const [searchFields, setSearchFields] = useState({
    field: "",
    parentField: "",
    parentFieldValue: "",
    search: "",
    page: 1,
    record: 10,
  });
  const [hasExpectedValues, setHasExpectedValues] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [optionErrorMsg, setOptionErrorMsg] = useState("");
  const [selectOption, setSelectOption] = useState<any>({
    label: expectedValuesOption?.find((item) => item.value === editValue.value)
      ?.description,
    value: editValue.value,
  });
  const [enableSearch, setEnableSearch] = useState(false);
  const onOpen = () => {
    const fieldConfig = documentFieldsExpectedValueConfigs?.find(
      (item) => item.field === editValue.name
    );
    if (fieldConfig) {
      let parentFieldValue = "";
      let keys = editValue?.key?.split(".");
      const getParentFieldValueFromFields = (fieldName) =>
        getFieldValue("fields")?.find((field) => field.name === fieldName)
          ?.value;
      if (keys?.length > 2) {
        if (row) {
          parentFieldValue =
            getFieldValue(fieldConfig?.parentFieldConfig?.field ?? "", row)
              ?.value ||
            getParentFieldValueFromFields(
              fieldConfig?.parentFieldConfig?.field
            );
        } else {
          parentFieldValue =
            getFieldValue(keys.slice(0, 5).join("."))?.find((field) => {
              return field.name === fieldConfig?.parentFieldConfig?.field;
            })?.value ||
            getParentFieldValueFromFields(
              fieldConfig?.parentFieldConfig?.field
            );
        }
      } else {
        parentFieldValue = getParentFieldValueFromFields(
          fieldConfig?.parentFieldConfig?.field
        );
      }
      let searchField_ = {
        field: fieldConfig?.field ?? "",
        parentField: fieldConfig?.parentFieldConfig?.field ?? "",
        parentFieldValue: parentFieldValue,
        search: "",
        page: 1,
        record: 10,
      };
      setSearchFields(searchField_);
      setHasExpectedValues(true);
      getASyncExpectedValues(searchField_);
    }
  };
  useEffect(() => {
    const fieldConfig = documentFieldsExpectedValueConfigs?.find(
      (item) => item.field === editValue.name
    );
    setSelectOption({
      label: editValue.expectedFieldLabel,
      value: editValue.value,
    });
    if (fieldConfig) {
      let parentFieldValue = "";
      let keys = editValue?.key?.split(".");
      const getParentFieldValueFromFields = (fieldName) =>
        getFieldValue("fields")?.find((field) => field.name === fieldName)
          ?.value;
      if (keys?.length > 2) {
        if (row) {
          parentFieldValue =
            getFieldValue(fieldConfig?.parentFieldConfig?.field ?? "", row)
              ?.value ||
            getParentFieldValueFromFields(
              fieldConfig?.parentFieldConfig?.field
            );
        } else {
          parentFieldValue =
            getFieldValue(keys.slice(0, 5).join("."))?.find((field) => {
              return field.name === fieldConfig?.parentFieldConfig?.field;
            })?.value ||
            getParentFieldValueFromFields(
              fieldConfig?.parentFieldConfig?.field
            );
        }
      } else {
        parentFieldValue = getParentFieldValueFromFields(
          fieldConfig?.parentFieldConfig?.field
        );
      }

      let searchField_ = {
        ...searchFields,
        field: fieldConfig?.field ?? "",
        parentField: fieldConfig?.parentFieldConfig?.field ?? "",
        parentFieldValue: parentFieldValue,
      };
      setSearchFields(searchField_);
      setHasExpectedValues(true);
      //   getASyncExpectedValues(searchField_);
    } else if (editValue.expectedValues?.length > 0) {
      let expValues =
        editValue.expectedValues?.map((item) => {
          return {
            value: item,
            label: item,
          };
        }) ?? [];
      setExpectedValuesOption(expValues);
      setHasExpectedValues(true);
    }
    if (!["AccountClass", "DocumentClass"]?.includes(editValue.name)) {
      setEnableSearch(true);
    }
  }, []);

  const getASyncExpectedValues = async (
    searchField_?: Partial<TSearchFields>
  ) => {
    const fieldConfig = documentFieldsExpectedValueConfigs?.find(
      (item) => item.field === editValue.name
    );
    if (!fieldConfig) {
      return;
    }
    let searchField = {
      ...searchFields,
      ...searchField_,
    };
    setLoading(true);
    GetAsync(
      `${API_GATEWAY}/api/documentservice/documentfieldexpectedvalue/search`,
      true,
      undefined,
      {
        params: {
          field: editValue.name,
          parentField: searchField.parentField || undefined,
          parentFieldValue: searchField.parentFieldValue || undefined,
          search: searchField?.search || undefined,
          page: searchField.page || undefined,
          record: searchField.record || undefined,
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setExpectedValuesOption(res?.data?.expectedValues);
          setTotalCount(res?.data?.filteredRecordsCount);
          if (optionErrorMsg !== "") {
            setOptionErrorMsg("");
          }
        } else if (res.status === 400) {
          setOptionErrorMsg(res.data.Detail);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    hasExpectedValues,
    expectedValuesOption,
    selectOption,
    setSelectOption,
    getASyncExpectedValues,
    totalCount,
    loading,
    searchFields,
    optionErrorMsg,
    onOpen,
    enableSearch,
  };
};
export default useAsyncExpectedValues;
