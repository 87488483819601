import { alpha, Box, Popover, Typography } from "@mui/material";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { getActive } from "src/components/nav-section";
import useLocales from "src/hooks/useLocales";
import { DotIcon, ListItem } from "./NavItem";

interface Props {
  anchorEl: HTMLElement | null;
  handlePopoverClose: () => void;
  submenu?: {
    path: string;
    title: string;
    disabled?: boolean;
    role?: Boolean;
    color?: string;
  }[];
  title: string;
}

const NavItemSubmenuPopover = ({
  anchorEl,
  handlePopoverClose,
  submenu,
  title,
}: Props) => {
  const { translate } = useLocales();
  const { pathname } = useLocation();

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: 60,
      }}
      onClose={handlePopoverClose}
      hideBackdrop
      //   sx={{
      //     pointerEvents: "none",
      //   }}
      PaperProps={{
        sx: {
          bgcolor: "background.paper",
          borderRadius: "8px",
          p: 1,
          display: "flex",
          flexDirection: "column",
          width: "200px",
          border: `1px solid ${alpha("#d0d7de", 0.5)}`,
        },
      }}
    >
      <Box>
        <Typography
          sx={{
            fontWeight: "bold",
            color: "text.greyLight",
            fontSize: "12px",
            mb: 1,
            ml: 1,
          }}
          variant="subtitle2"
        >
          {translate(title)}
        </Typography>
      </Box>
      {submenu
        ?.filter((m) => m.role)
        .map((menu, index) => {
          const active = getActive(menu.path, pathname);
          return (
            <ListItem
              key={menu.path || index}
              component={RouterLink}
              to={menu.path}
              disabled={menu.disabled}
              //   role={menu.role}
              sx={{
                "&:hover, &.activeRoot, &.active": {
                  bgcolor: "#f5f5f5",
                },
              }}
            >
              {" "}
              <DotIcon active={active} color={menu.color} />
              <Typography
                sx={{
                  color: "text.greyLight",
                  fontSize: "12px",
                }}
                variant="subtitle2"
              >
                {translate(menu.title)}
              </Typography>
            </ListItem>
          );
        })}
    </Popover>
  );
};

export default NavItemSubmenuPopover;
