const exportConfiguration = {
  view_export_template_logs: `View export template logs`,
  export_template_logs: `Export template logs`,
  exported_at: `Exported at`,
  no_logs_found: `No logs found`,
  delete_template: `Delete template`,
  export_template: `export template`,
  Export_template: `Export template`,
  delete_template_warning: `Deleted template(s) cannot be recovered`,
  template_deleted_successfully: `Template(s) deleted successfully`,
  add_export_template: `Add export template`,
  csv_exported: `CSV exported`,
};
export default exportConfiguration;
