import React from 'react';
import { Box } from '@mui/material';

const FilterIcon = ({ height = 24, width = 24, color = '#657482' }) => {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.88158 19.0254V21.9286M4.88158 1.92969V13.8652M11.6555 10.6386C13.0806 10.6386 14.2361 9.48311 14.2361 8.05799C14.2361 6.63286 13.0806 5.47734 11.6555 5.47734M11.6555 10.6386C10.2303 10.6386 9.07482 9.48311 9.07482 8.05799C9.07482 6.63286 10.2303 5.47734 11.6555 5.47734M11.6555 10.6386L11.6556 21.9293M11.6555 5.47734L11.6556 1.92969M18.4295 17.0914C19.8546 17.0914 21.0101 15.9359 21.0101 14.5108C21.0101 13.0856 19.8546 11.9301 18.4295 11.9301M18.4295 17.0914C17.0044 17.0914 15.8489 15.9359 15.8489 14.5108C15.8489 13.0856 17.0044 11.9301 18.4295 11.9301M18.4295 17.0914L18.4297 21.9304M18.4295 11.9301L18.4297 1.92969M7.46207 16.4475C7.46207 17.8726 6.30656 19.0281 4.88143 19.0281C3.4563 19.0281 2.30078 17.8726 2.30078 16.4475C2.30078 15.0224 3.4563 13.8669 4.88143 13.8669C6.30656 13.8669 7.46207 15.0224 7.46207 16.4475Z'
          stroke={color}
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Box>
  );
};

export default FilterIcon;
