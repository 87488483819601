import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { WorkflowHistoryDetailsEntity } from "src/@types/workflows/workflowHistory";
import useLocales from "src/hooks/useLocales";
import TimelinePopover from "src/pages/Documents/Finance/history/Popover";

interface WorkflowDetailItemProps {
  workflowDetail: WorkflowHistoryDetailsEntity;
}

const FormatValue = ({ value }: { value: string }) => {
  const [valueAnchorEl, setValueAnchorEl] = useState<HTMLElement | null>(null);

  const handleValuePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setValueAnchorEl(event.currentTarget);
  };

  const handleValuePopoverClose = () => {
    setValueAnchorEl(null);
  };
  const [copied, setCopied] = useState(false);

  return (
    <>
      <Typography
        variant="body2"
        onMouseEnter={handleValuePopoverOpen}
        onMouseLeave={handleValuePopoverClose}
        sx={{
          alignContent: "right",
          textAlign: "right",
          overflow: "hidden",
          maxWidth: 150,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          cursor: "pointer",
        }}
        onClick={() => {
          setCopied(true);
          navigator.clipboard.writeText(value);
          setTimeout(() => {
            setCopied(false);
          }, 2000);
        }}
      >
        {value}
      </Typography>
      <TimelinePopover
        title={value}
        anchorEl={valueAnchorEl}
        handlePopoverClose={handleValuePopoverClose}
        clickToCopy
        copied={copied}
      />
    </>
  );
};

const WorkflowDetailItem: React.FC<WorkflowDetailItemProps> = (props) => {
  const {
    workflowDetail: { newWorkflow, oldWorkflow, updatedByEmail },
  } = props;
  const theme = useTheme();
  const { translate } = useLocales();
  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 1,
        width: "300px",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          px: 1,
          py: 0.5,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: `secondary.light`,
        }}
      >
        <Box>
          <Typography variant="subtitle2">
            {translate("workflows.workflow_updated")}
          </Typography>
        </Box>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{
          mx: 1,
          my: 0.5,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="body2" color={theme.palette.text.greyLight}>
            {translate("workflows.new_workflow")}
          </Typography>
        </Box>
        <FormatValue value={newWorkflow} />
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{
          mx: 1,
          my: 0.5,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="body2" color={theme.palette.text.greyLight}>
            {translate("workflows.old_workflow")}
          </Typography>
        </Box>
        <FormatValue value={oldWorkflow} />
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{
          mx: 1,
          my: 0.5,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="body2" color={theme.palette.text.greyLight}>
            {translate("updated_by")}
          </Typography>
        </Box>
        <Box>
          <Tooltip title={updatedByEmail} placement="top">
            <Typography
              variant="body2"
              noWrap
              sx={{
                textOverflow: "ellipsis",
                width: 150,
                cursor: "pointer",
              }}
            >
              {updatedByEmail}
            </Typography>
          </Tooltip>
        </Box>
      </Stack>
    </Box>
  );
};

export default WorkflowDetailItem;
