import { Skeleton, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import useLocales from "src/hooks/useLocales";

interface CustomCardProps {}

export default function CustomCardSkeleton({}: CustomCardProps) {
  const theme = useTheme();
  const { translate } = useLocales();
  return (
    <Box
      sx={{
        borderRadius: "8px",
        width: 318,
        height: 190,
        border: `1px solid ${theme.palette.action.disabledBackground}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          bgcolor: "background.neutral",
          alignItems: "center",
          p: 1,
          pb: 0.5,
          mb: 0.5,
          borderTopLeftRadius: "7px",
          borderTopRightRadius: "7px",
        }}
      >
        <Skeleton
          sx={{
            bgcolor: "action.disabledBackground",
          }}
        >
          <Typography variant="h6" sx={{ color: "" }}>
            {"workflow name"}
          </Typography>
        </Skeleton>
        <Skeleton
          variant="circular"
          sx={{
            bgcolor: "action.disabledBackground",
            height: "15px",
            width: "15px",
            borderRadius: "12px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // py: 0.125,
          px: 1,
        }}
      >
        <Skeleton>
          <Typography variant="body1" sx={{ color: "text.greyLight" }}>
            {translate("Conditions configured")}
          </Typography>
        </Skeleton>
        <Skeleton
          sx={{
            bgcolor: "action.disabledBackground",
            height: "30px",
            width: "90px",
            borderRadius: "12px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
          // py: 0.25,
        }}
      >
        <Skeleton>
          <Typography variant="body1" sx={{ color: "text.greyLight" }}>
            {translate("Approvers")}
          </Typography>
        </Skeleton>
        <Skeleton
          sx={{
            bgcolor: "action.disabledBackground",
            height: "30px",
            width: "90px",
            borderRadius: "12px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
          // py: 0.25,
        }}
      >
        <Skeleton>
          <Typography variant="body1" sx={{ color: "text.greyLight" }}>
            {translate("Review Allowed")}
          </Typography>
        </Skeleton>
        <Skeleton
          variant="circular"
          sx={{
            bgcolor: "action.disabledBackground",
            height: "15px",
            width: "15px",
            borderRadius: "12px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
          // py: 0.25,
        }}
      >
        <Skeleton>
          <Typography variant="body1" sx={{ color: "text.greyLight" }}>
            {translate("Disable Approval")}
          </Typography>
        </Skeleton>
        <Skeleton
          variant="circular"
          sx={{
            bgcolor: "action.disabledBackground",
            height: "15px",
            width: "15px",
            borderRadius: "12px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
          // py: 0.5,
        }}
      >
        <Skeleton variant="text">
          <Typography variant="body1" sx={{ color: "text.greyLight" }}>
            {translate("Created on date and time in am/pm format")}
          </Typography>
        </Skeleton>
      </Box>
    </Box>
  );
}
